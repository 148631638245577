import payment from '../../../app/service/domain/payment.js';
import user from '../../../app/service/domain/user.js';
import config from '../../../app/service/configs/config.js';
import helper from '../../../app/service/helper.js';
import modal from '../../../app/service/domain/modal.js';
import wsEvents from '../../../app/service/configs/ws-events.js';
import popup from '../../../app/service/domain/popup.js';
import apayLangReplacements from '../apay-langs-remap.js';
import notification from '../../../app/service/rx/notification$.js';
import analytics from '../../../app/service/analytics/analytics.js';

import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = [
    '$scope',
    'payment',
    'user',
    'config',
    'helper',
    'system$',
    'modal',
    'ws$',
    'wsEvents',
    'popup',
    'apayLangReplacements',
    'notification$',
    '$translate',
    'analytics',
    '$http'
  ];
  function controller(
    $scope,
    _payment,
    _user,
    _config,
    _helper,
    _system$,
    _modal,
    _ws$,
    _wsEvents,
    _popup,
    langReplacements,
    _notification$,
    $translate,
    _analytics,
    _http,
  ) {
    $scope.preloader = false;
    $scope.system = {};
    $scope.formData = {
      amount: 0,
      paymethod: '',
      paysystem_id: '',
      place: '',
    };
    $scope.checkPendingWithdrawals = false;
    $scope.showCryptoPreloader = false;

    const DEFAULT_PAYMENT_LABEL = "Deposit";
    const BASE_REQUEST = {
      "countryCode": "",
      "currencyCode": "",
      "merchantCapabilities": [
        "supports3DS"
      ],
      "supportedNetworks": [
        "visa",
        "masterCard",
        "amex",
        "discover"
      ],
      "total": {
        "label": "Betinia.dk Deposit",
        "type": "final",
        "amount": "",
      }
    };

    this.$onChanges = () => {
      if (typeof this.system === 'object') {
        this.system.rules.showAmount = !this.system.hide_amount;
        $scope.formData.amount = this.system.amount || this.system.rules.default;
        $scope.system = this.system;
        $scope.formData.place = this.place || 'cashbox';

        $scope.formData.paymethod = this.system.rules.system;
        $scope.formData.paysystem_id = this.system.id;
        $scope.lang = langReplacements[_config.currentLang] || 'en_US';
        $scope.applePayAvailable = !!window.ApplePaySession;

        if (this.quick) {
          $scope.formData.tryQuick = true;
        }
      }
    };

    function listenTransactionEvents() {
      $scope.preloader = true;
      $scope.wssub = _ws$.pipe(filter(({ event }) => event === _wsEvents.cashierStatus)).subscribe((message) => resolveOnSocket(message));
    }

    function openSuccessNotification() {
      _notification$.next({
        type: 'success',
        code: 0,
        text: $translate.instant('deposit.transaction_success'),
      });
    }

    function resolveOnSocket({ data: { status } }) {
      $scope.transactionStatus = status;
      $scope.preloader = false;
      if ($scope.transactionStatus === 'success') {
        _popup.close();
        openSuccessNotification();
        $scope.preloader = false;
        $scope.wssub.unsubscribe();
        return;
      }
    }

    $scope.applePayUnavailable = () => {
      _notification$.next({
        type: 'error',
        code: 0,
        text: $translate.instant('cashbox.use.safari'),
      });
    }

    $scope.handleApplePay = () => {
      $scope.preloader = true;

      async function validateMerchant(validationUrl) {
        if (!$scope.system.applepay?.validationUrl) throw new Error("Merchant validation url is not defined");
        return _http
          .post(
            $scope.system.applepay.validationUrl,
            { validationUrl }
          );
      }
      function composeRequest() {
        return Object.assign({}, BASE_REQUEST, {
          "countryCode": _user?.profile?.country,
          "currencyCode": $scope.system?.rules?.currency?.code || _user?.profile?.currency,
          "total": {
            "label": $scope.system?.applepay?.paymentLabel || DEFAULT_PAYMENT_LABEL,
            "amount": $scope.formData.amount,
          }
        });
      }

      if (!ApplePaySession) {
        return;
      }

      const session = new ApplePaySession(3, composeRequest());

      session.onvalidatemerchant = async ({ validationURL }) => {
        const { data } = await validateMerchant(validationURL);

        session.completeMerchantValidation(data);
      };

      session.onpaymentauthorized = ({ payment: { token } }) => {
        listenTransactionEvents()
        _payment
          .deposit({
            ...$scope.formData,
            token: JSON.stringify(token)
          })
          .then(() => {
            session.completePayment({
              "status": ApplePaySession.STATUS_SUCCESS
            });
          })
          .catch(error => {
            console.error('::: ERROR :::', error);
            session.abort();
            window.Sentry && window.Sentry.captureException(error);
          })
      };

      session.oncancel = event => {
        console.warn('::: APPLE SESSION CANCELED ::: ', event);
        $scope.preloader = false;
        _analytics.sendToDataLayer({
          event: 'applepay',
          eventCategory: 'deposit',
          eventAction: 'cancel',
        });
      };

      session.begin();
    }
  }

  new Controller('batmanApplePayDepositForm', controller, {
    system: '<',
    place: '<',
    quick: '<',
  });
})();

(function () {
  'use strict';

  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanApplePayDepositForm${i}`, 'batmanApplePayDepositForm');
  });
})();
