import '../../service/domain/http.js';

(function () {
  'use strict';

  controller.$inject = ['$q', 'http'];

  app.factory('playgrounds', controller);

  function controller($q, _http) {
    return {
      cb: null,

      collection(params = {}) {
        const defer = $q.defer(),
          alias = 'playgroundsList',
          config = {
            params,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      buy(options) {
        const defer = $q.defer(),
          alias = 'playgroundsBuy',
          config = {
            params: {},
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, options, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      assignCityReward(options) {
        const defer = $q.defer(),
          alias = 'playgroundsAssignCityReward',
          config = {
            params: {},
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, options, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };
  }
})();
