import achievement from '../../app/service/domain/achievement.js';
import config from '../../app/service/configs/config.js';
import system$ from '../../app/service/rx/system$.js';
import user from '../../app/service/domain/user.js';

(function () {
    'use strict';

    const directive = { name: 'betiniaGetColossusProgress' };

    controller.$inject = ['achievement', 'config', 'system$', 'user'];

    function controller(_achievement, _config, _system$, _user) {
        function link(scope) {

            scope.collection = [];
            scope.current = 0;
            scope.progress = 2;
            scope.lock = true;
            scope.preloader = true;


            // initializing colossus
            function init() {
                loadColossus()
                    .then(() => {
                        let current = scope.collection.findIndex(i => i.status < 2);
                        scope.current = current !== -1 ? current : scope.collection.length - 1;
                        scope.achieved = (scope.current === 0 && getProgress(scope.current) >= 0) || (scope.current === scope.collection.length - 1 && getProgress(scope.current) === 100) ? scope.current : scope.current - 1;

                        scope.lock = false;
                        scope.preloader = false;
                    });
            }

            // getting collection
            function loadColossus() {
                return _achievement
                    .list({
                        category: 'colossus'
                    })
                    .then(({ result }) => {
                        if (scope.collection && scope.current) {
                            Object.assign(scope.collection, result);

                            return;
                        }

                        scope.collection = result.sort((a, b) => a.progress[1] - b.progress[1]);
                    });
            }

            // getting progress
            function getProgress(current) {
                let progress;
                if (current === 0) {
                    scope.pointsCurrent = scope.collection[scope.current].progress[0];
                    scope.pointsNeeded = scope.collection[scope.current].progress[1];

                    progress = scope.collection[scope.current].progress[0] / scope.collection[scope.current].progress[1] * 100;
                } else {
                    const pointsCurrent = scope.collection[scope.current].progress[0] - scope.collection[scope.current - 1].progress[1];
                    const pointsNeeded = scope.collection[scope.current].progress[1] - scope.collection[scope.current - 1].progress[1];

                    scope.pointsCurrent = pointsCurrent < 0 ? 0 : pointsCurrent;
                    scope.pointsNeeded = pointsNeeded;

                    progress = pointsCurrent / pointsNeeded * 100;
                }

                return progress;
            }

            init();
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive(directive.name, controller);
})();
