import popup from '../../app/service/domain/popup.js';
import contentBuffer from '../../app/service/domain/content-buffer.js';
import user from '../../app/service/domain/user.js';


(function(){

  'use strict';

  controller.$inject = ['popup', 'contentBuffer', 'user'];

  function controller( _popup, _contentBuffer, _user ){

    function link( scope, element, attrs ){

      const clickHandler = () => {
        const attrsValue = scope.$eval( attrs.betiniaPopupClose );
        const config = typeof attrsValue === 'object' ? attrsValue : { name: attrs.betiniaPopupClose } ;

        _popup.close( config );
      }

      element[0].addEventListener('click', clickHandler);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('click', clickHandler);
      });

    }

    return {
      restrict: 'A',
      link:link
    };
  }

  app.directive( 'betiniaPopupClose', controller );

})();

