import user$ from '../../../app/service/rx/user$.js';
import user from '../../../app/service/domain/user.js';
import system$ from '../../../app/service/rx/system$.js';

import { filter } from 'rxjs/operators';

(function () {
    'use strict';

    controller.$inject = ['$scope', 'system', 'user$', 'user', 'system$'];

    function controller($scope, _system, _user$, _user, _system$) {
        let subscription = null;

        $scope.preloader = false;

        $scope.withdrawType = 'withdraw';
        $scope.depositType = 'deposit';

        $scope.country = null;
        $scope.currency = null;
        $scope.type = $scope.depositType;

        $scope.countries = [];
        $scope.currencies = [];

        this.$onInit = () => {
            $scope.preloader = true;

            Promise.all( [ _system.info(), _system.countries() ] )
                .then(results => {
                    setCountries(results[1]);

                    return results[0]
                })
                .then(( { result: { country: { code: currentCode }}} ) => {
                    const countryByCode = $scope.countries.find(c => c.code === currentCode);

                    if (countryByCode) {
                        setCountryAndCurrency(countryByCode.code, countryByCode.currency[0].code);
                    } else {
                        setCountryAndCurrency($scope.countries[0].code, $scope.currencies[0].key);
                    }
                })
                .finally(() => {
                    $scope.preloader = false;
                })
        };

        function setCountryAndCurrency(countryCode, currencyKey) {
            $scope.country = _user.profile.country || countryCode;
            $scope.currency = _user.profile.currency || currencyKey;
        }

        const prepareCurrencyList = (list) => list.map(({ code: key, name, symbol: icon }) => ({ key, name, icon }));

        function setCountries (answer) {
            $scope.countries = answer.result;
            $scope.currencies = prepareCurrencyList(answer.result[0].currency);
        }

        $scope.$watch('country', (newVal) => {
            $scope.countries.filter((item) => {
                if(item.code === newVal) {
                    $scope.currencies = prepareCurrencyList(item.currency);
                    $scope.currency = $scope.currencies.some(c => c.key === _user.profile.currency)
                        ? _user.profile.currency
                        : $scope.currencies[0].key;
                }
            });
        });

        subscription = _system$.pipe(filter((message) => message.action === 'login')).subscribe(()=>{
            setCountryAndCurrency();
        });

        this.$onDestroy = () => {
            subscription.unsubscribe();
        };
    }

    new Controller('paymentsPage', controller);

})();

(function () { 'use strict'; new Batman('batmanPaymentsPage1', 'paymentsPage') })();
