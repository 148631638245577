const aliases = {

    home: {
        page: true,
        name: 'app.root.l1.home'
    },
    games: {
        page: true,
        name: 'app.root.l1.c.casino'
    },
    liveCasino: {
      page: true,
      name: 'app.root.l1.c1.livecasino'
    },
    login: {
        page: true,
        name: 'app.root.paynplay'
    },
    registration: {
        page: true,
        name: 'app.root.paynplay',
        public: true
    },
    realGame: {
        page: true,
        name: 'app.root.game.real'
    },
    promo: {
        page: true,
        name: 'app.root.l1.promo.list'
    },
    profile: {
        page: true,
        name: 'app.root.l1.account.info'
    },
    paynplay: {
        page: true,
        name: 'app.root.paynplay'
    },
    deposit: {
        page: false,
        name: 'cashbox',
        private: true
    },
    withdrawal: {
        page: false,
        name: 'cashbox',
        private: true,
        content: { tab1: 2 }
    },
    fastRegistration: {
        page: true,
        name: 'app.root.paynplay'
    },
    tournaments: {
        page: true,
        name: 'app.root.l1.tournament.list'
    },
    shop:{
        page:true,
        name:'app.root.l1.shop'
    },
    bet: {
        page: true,
        name: 'app.root.l1.sportpage.home'
    },
    sport: {
        page: true,
        name: 'app.root.l1.sportpage.home'
    },
    sportsBook: {
        page: true,
        name: 'app.root.l1.sportpage.home'
    },
    live: {
        page: true,
        name: 'app.root.l1.c1.livecasino'
    },
    sportTournament: {
        page: true,
        name: 'app.root.l1.sporttournament'
    },
    playgrounds: {
        page: true,
        name: 'app.root.playgrounds'
    },
    colossus: {
        page: true,
        name: 'app.root.l1.colossus'
    },
    responsibleGaming: {
        page: true,
        name: 'app.root.l1.account.responsible'
    },
    balanceHistory: {
        page: true,
        name: 'app.root.l1.account.history'
    },
    bonus: {
      page: true,
      name: 'app.root.l1.account.bonuses',
      private: true
    },
    missedData: {
        page: true,
        name: 'app.root.missedData'
    },
    notFound: {
        page: true,
        name: 'app.root.l1.404'
    },


    bonus_crab: {
        page: true,
        name: 'app.root.claw_games.gamepage',
        params: {name: 'cash-crab'}
    },
    cash_crab: {
        page: true,
        name: 'app.root.claw_games.gamepage',
        params: {name: 'cash_crab'}
    },
    claw: {
        page: true,
        name: 'app.root.claw_games.gamepage'
    },


    bonuses: {
        page: true,
        name: 'app.root.l1.account.bonuses'
    },
    terms: {
        page: true,
        name: 'app.root.l1.info.terms'
    },
    spinRally: {
        page: true,
        name: 'app.root.l1.spinRallyTournamentList'
    },
    luckyWinner: {
        page: true,
        name: 'app.root.l1.promo.lucky'
    },
    weeklyChallenges: {
        page: true,
        name: 'app.root.l1.challenge'
    },
    achievements: {
        page: true,
        name: 'app.root.l1.achievements'
    },
    contact: {
      page: true,
      name: 'app.root.l1.info.contact'
    },
    virtual: {
        page: true,
        name: 'app.root.l1.sportpage.virtual'
    },
    jackpots: {
        page: true,
        name: 'app.root.l1.c2.jackpots'
    },
    verification: {
        page: true,
        name: 'app.root.l1.account.verification'
    },
    weeklyChallengesSport: {
      page: true,
      name: 'app.root.l1.challenge',
      params: {tab: 2}
    },
    weeklyChallengesCasino: {
      page: true,
      name: 'app.root.l1.challenge',
      params: {tab: 1}
    },
    category: {
      page: true,
      name: 'app.root.l1.c.category'
    },
    application: {
      page: true,
      name: 'app.root.l1.application'
    },

};

export {aliases};
