import user from '../../app/service/domain/user.js';

(function () {
  const directive = { name: 'betiniaInitialAchievement' };

  controller.$inject = ['user'];

  function controller(user) {
    function link(scope) {
      scope.$watchCollection('collection', (collection) => {
        if (collection.length && user.status) {
          if(!scope.selectedAchivement) {
            scope.selectedAchivement = collection[0];
          } else {
            scope.selectedAchivement = collection.find(e => e.id === scope.selectedAchivement.id);
          }
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
