import '../../../service/helper.js';
import '../../../service/domain/account.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/domain/modal.js';
(function () {
  'use strict';

  const component = { name: 'lindaTermsChangedModal' };

  controller.$inject = ['$scope', 'helper', 'account', 'contentBuffer', 'modal'];

  function controller($scope, _helper, _account, _contentBuffer, _modal) {
    $scope.preloader = false;
    $scope.formData = {
      accept: false,
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      const approve_token = _contentBuffer.read('modal').data.approve_token;

      _account
        .acceptNewTerms({ approve_token })
        .then((a) => {
          _modal.answer = true;
        })
        .catch((e) => {
          _modal.answer = false;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
