import '../../../service/helper.js';
import '../../../service/domain/user.js';
import '../../../service/dk/dk_limits.js';
(function () {
  'use strict';

  const component = { name: 'denmarkProfileRealityCheck' };

  const group = 'reality_check';

  controller.$inject = ['$scope', 'helper', 'user', 'dkLimits'];

  function controller($scope, _helper, _user, _dkLimits) {
    $scope.preloader = false;

    $scope.formData = {
      unit: '1',
      reality_check: 0,
    };

    $scope.limit = {
      unit: '1',
      reality_check: 0,
    };

    $scope.formField = {
      //- minutes
      1: {
        min: 1,
        max: 240,
      },
    };

    function setLimit() {
      if (typeof _user.profile[group].value === 'string') {
        $scope.formData.unit = _user.profile[group].value[0];
        $scope.formData.reality_check = parseInt(_user.profile[group].value.slice(1));

        $scope.limit = { ...$scope.formData };
      }
    }

    setLimit();

    $scope.submit = _helper.form.validator((form) => {
      const o = {
        unit: parseInt($scope.formData.unit),
        reality_check: parseInt($scope.formData.reality_check),
      };

      $scope.preloader = true;

      _dkLimits.realityCheck
        .set(o)
        .then((a) => {
          setLimit();
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
