import analytics from '../../app/service/analytics/analytics.js';
// @todo move to f core after testing
(function () {
  'use strict';

  const directive = { name: 'track' };

  controller.$inject = [ 'analytics' ];

  function controller( _analytics ){

    function link( scope, element, attrs ){
      const clickHandler = () => {
        _analytics.send(
          attrs.gCategory,
          attrs.gAction,
          attrs.gLabel
        )
      };
      element[0].addEventListener('click', clickHandler);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('click', clickHandler);
      });
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );
})();
