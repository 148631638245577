import '../../../service/domain/payment.js';
import '../../../service/domain/user.js';
import '../../../service/configs/config.js';
import '../../../service/helper.js';
import '../../../service/rx/system$.js';
import '../../../service/rx/ws$.js';
import '../../../service/configs/ws-events.js';
import '../../../service/rx/notification$.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/modal.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  function makeForm(path, params, el) {
    document.querySelector('#depositForm')?.remove();

    const form = document.createElement('form');

    form.setAttribute('method', 'POST');
    form.setAttribute('action', path);
    form.setAttribute('id', 'depositForm');
    form.setAttribute('target', 'depositFrame');

    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);
        form.appendChild(hiddenField);
      }
    }

    el.append(form);
    form.submit();
  }

  controller.$inject = [
    '$scope',
    'payment',
    '$element',
    'user',
    'config',
    'helper',
    'system$',
    'ws$',
    'wsEvents',
    'notification$',
    'popup',
    '$translate',
    'modal',
  ];

  function controller(
    $scope,
    _payment,
    $element,
    _user,
    _config,
    _helper,
    _system$,
    _ws$,
    _wsEvents,
    _notification$,
    _popup,
    $translate,
    _modal
  ) {
    $scope.preloader = false;
    $scope.system = {};

    $scope.transactionStatus = '';
    $scope.depositStep = 1;
    $scope.formData = {
      amount: 0,
      paymethod: '',
      paysystem_id: '',
      place: '',
    };
    $scope.checkPendingWithdrawals = false;
    $scope.showMinMaxWarning = false;
    let subscribtion;

    const isAmountInRange = (amount, minAmount, maxAmount) => {
      return minAmount <= amount && amount <= maxAmount;
    };

    this.$onInit = () => {
      if (typeof this.system === 'object') {
        $scope.formData.amount = this.system.amount || this.system.rules.default;
        $scope.scenario = this.system.scenario;
      }
    };
    this.$onChanges = () => {
      if (typeof this.system === 'object') {
        this.system.rules.showAmount = !this.system.hide_amount;
        $scope.system = this.system;
        $scope.formData.place = this.place || 'cashbox';
        $scope.formData.amount = this.system.amount || this.system.rules.default;
        $scope.formData.paymethod = this.system.rules.system;
        $scope.formData.paysystem_id = this.system.id;
        if (this.amount && !isAmountInRange(this.amount, this.system.min_amount, this.system.max_amount)) {
          $scope.showMinMaxWarning = true;
        }
      }
    };

    function listenTransactionEvents() {
      window.addEventListener('message', resolveOnPostMessage);
      subscribtion = _ws$
        .pipe(filter(({ event }) => event === _wsEvents.cashierStatus))
        .subscribe((message) => resolveOnSocket(message));
    }

    function openSuccessNotification() {
      _notification$.next({
        type: 'success',
        code: 0,
        text: $translate.instant('deposit.transaction_success'),
      });
    }

    function resolveOnSocket(message) {
      $scope.transactionStatus = message.data.status;
      $scope.iframeLink = '';
      if ($scope.transactionStatus === 'success') {
        _popup.close();
        openSuccessNotification();
        return;
      }
      $scope.depositStep = 3;
    }

    function resolveOnPostMessage(message) {
      if (message.data === 'transaction-success') {
        subscribtion.unsubscribe();
        openSuccessNotification();
        _popup.close();
      }
      if (message.data === 'transaction-fail') {
        subscribtion.unsubscribe();
        $scope.depositStep = 3;
      }
    }

    function submitForm() {
      $scope.preloader = true;
      $scope.iframeLink = `/preloader.html?login=${_user.profile.login}`;
      _payment
        .deposit($scope.formData)
        .then((answer) => {
          $scope.preloader = false;
          if (answer.result.method.toLowerCase() === 'post') {
            makeForm(answer.result.url, answer.result.post, $element);
          } else {
            $scope.depositStep = 2;
            $scope.iframeLink = answer.result.url;
            listenTransactionEvents();
          }
          $scope.transactionAlias = answer.result.alias;
        })
        .catch((a) => {
          $scope.depositStep = 3;
          $scope.transactionStatus = 'error';
        })
        .finally((a) => {
          $scope.preloader = false;
        });
    }

    $scope.submit = _helper.form.validator(() => {
      if ($scope.checkPendingWithdrawals) {
        _modal
          .open('cancel-pending-withdrawals')
          .then(() => {
            submitForm();
          })
          .catch(() => {})
          .finally(() => {});
        return;
      }
      submitForm();
    });

    this.$onDestroy = () => {
      subscribtion?.unsubscribe();
      window.removeEventListener('message', resolveOnPostMessage);
    };
  }

  new Controller('cashboxDepositIframeForm', controller, {
    system: '<',
    place: '<',
    amount: '<',
  });
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanCashboxDepositIframeForm${i}`, 'cashboxDepositIframeForm');
  });
})();
