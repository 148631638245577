import achievement from "../../app/service/domain/achievement.js";
import config from "../../app/service/configs/config.js";
import system$ from "../../app/service/rx/system$.js";
import user from "../../app/service/domain/user.js";
import gsap from 'gsap';
import * as THREE from 'three';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import ScrollTrigger from "../../libs/gsap/ScrollTrigger.min.js";

(function () {

    'use strict';

    const directive = { name: 'betiniaColossus2' };

    controller.$inject = [ 'achievement' , 'config' , '$timeout', 'user$' ];

    function controller( _achievement, _config, $timeout, _user$ ){

        function link( scope ){

            scope.collection = [];
            scope.current = 0;
            scope.progress = 10;

            scope.changeColossus = index => {
                scope.current = index;
            };

            const setProgress = (current)=>{
                const defaultProgress = 30;
                let currentPoint;
                let needPoint;

                switch( current ) {
                    case 0:
                        scope.progress =  (defaultProgress + scope.collection[0].progress[2]*0.7) * 0.33;
                        break;
                    case 1:
                        currentPoint = scope.collection[1].progress[0] - scope.collection[0].progress[1];
                        needPoint = scope.collection[1].progress[1] - scope.collection[0].progress[1];
                        scope.progress = 33.3 + ( (currentPoint/(needPoint/100) ) * 0.33 ) ;
                        break;
                    case 2:
                        currentPoint = scope.collection[2].progress[0] - scope.collection[1].progress[1];
                        needPoint = scope.collection[2].progress[1] - scope.collection[1].progress[1];
                        scope.progress = 66.6 + ( (currentPoint/(needPoint/100) ) * 0.33 ) ;
                        break;
                }
            }

            const loadColossus = () => {
                return _achievement
                    .list({
                        category:'colossus',
                        count:3,
                        order:''
                    })
                    .then(a => {
                        if (scope.collection && scope.current) {
                            Object.assign(scope.collection, a.result);

                            return;
                        }

                        scope.collection = a.result;
                        let current = scope.collection.findIndex( i => i.status < 2 );
                        scope.current = current !== -1 ? current : scope.collection.length-1

                        return;
                    });
            }

            const init = () => {
                loadColossus()
                    .then(() => {
                        setProgress( scope.current );
                    });
            }

            const userSubscription = _user$.subscribe(user => {
                if (user.status) {
                    loadColossus();
                }
            });

            scope.$onDestroy = () => userSubscription.unsubscribe();

            init();
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
