import '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      collection(o) {
        const defer = $q.defer(),
          alias = 'dashboard',
          config = {
            cache: false,
            params: o,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );
        return defer.promise;
      },
    };
  }

  app.factory('dashboard', controller);
})();
