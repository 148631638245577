import deposit from '../../app/service/domain/deposit.js';

(function () {

    'use strict';

    const directive = { name: 'betiniaMultipleQuickDeposit' };

    controller.$inject = [ 'deposit' ];

    function controller( _deposit ){

        function link( scope, element, attrs ){

            scope.preloader = false;
            scope.isQuickDeposit = false;

            scope.paysystem = {};

            scope.formData = {
                amount: 0,
                paymethod: '',
                paysystem_id: ''
            }

            scope.selectCard = cardIndex => {
                scope.selectedDeposit = scope.depositCollection[cardIndex];
                scope.paysystem = {
                    ...scope.depositCollection[cardIndex].paysystem,
                    amount: typeof scope.formData.amount === 'string' ? scope.formData.amount.split('.')[0] * 1 : scope.formData.amount,
                    depositIcon: scope.depositCollection[cardIndex].icon};
            };

            _deposit
                .collection({})
                .then(a => {
                    if(a.result.length) {

                        const amount = typeof a.result[0].last_transaction.amount === 'string' ? a.result[0].last_transaction.amount.split('.')[0] * 1 : a.result[0].last_transaction.amount;

                        scope.depositCollection = a.result;
                        scope.selectCard(0);
                        scope.paysystem = a.result[0].paysystem;
                        scope.formData.amount = amount;
                        scope.paysystem.amount = amount;
                        scope.paysystem.depositIcon = a.result[0].icon;
                        scope.isQuickDeposit = true;
                    }

                })
                .catch(e => {})


        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();


// (function() {
//
//     'use strict';
//
//     const component = { name: 'BetiniaMultipleQuickDeposit' };
//
//     controller.$inject = ['$scope', 'deposit'];
//
//     function controller($scope, _deposit) {
//
//         $scope.preloader = false;
//         $scope.isQuickDeposit = false;
//
//         $scope.paysystem = {};
//
//         $scope.formData = {
//             amount: 0,
//             paymethod: '',
//             paysystem_id: ''
//         }
//
//         $scope.selectCard = cardIndex => {
//             $scope.selectedDeposit = $scope.depositCollection[cardIndex];
//             $scope.paysystem = {
//                 ...$scope.depositCollection[cardIndex].paysystem,
//                 amount: typeof $scope.formData.amount === 'string' ? $scope.formData.amount.split('.')[0] * 1 : $scope.formData.amount,
//                 depositIcon: $scope.depositCollection[cardIndex].icon};
//         };
//
//         this.$onInit = () => {
//
//             _deposit
//                 .collection({})
//                 .then(a => {
//
//                     if(a.result.length) {
//
//                         const amount = typeof a.result[0].last_transaction.amount === 'string' ? a.result[0].last_transaction.amount.split('.')[0] * 1 : a.result[0].last_transaction.amount;
//
//                         $scope.depositCollection = a.result;
//                         $scope.selectCard(0);
//                         $scope.paysystem = a.result[0].paysystem;
//                         $scope.formData.amount = amount;
//                         $scope.paysystem.amount = amount;
//                         $scope.paysystem.depositIcon = a.result[0].icon;
//                         $scope.isQuickDeposit = true;
//                     }
//
//                 })
//                 .catch(e => {})
//
//         };
//
//     }
//
//     app.component(component.name, {
//         controller,
//         templateUrl: app.getTU(component.name),
//     });
//
// })();
