import config from '../../app/service/configs/config.js';
(function () {
  const directive = { name: 'betiniaSplider' };

  controller.$inject = ['config'];

  function controller(_config) {
    function link(scope, element, attrs) {
      if (typeof Splide !== 'function') {
        return;
      }

      const o = scope.$eval(attrs[directive.name]);
      const className = o.className || element[0];

      scope.spliderInstance;

      const { collectionKey, config } = scope.$eval(attrs[directive.name]);

      const buildSlider = async () => {
        scope.spliderInstance = new Splide(className, config);
        scope.spliderInstance.mount();
      }

      const destroySlider = async () => {
        scope.spliderInstance.destroy(true);
        scope.$watchCollectionspliderInstance = null;
      }

      const renewSlider = async () => {
        if (scope.spliderInstance) {
          await destroySlider();
        }
        buildSlider();
      }

      scope.$watchCollection(collectionKey, (collection) => {
        if (collection && collection.length) {
          renewSlider();
        }
      });

      // when there is no collectionKey for slider we call renewSlider function from scope initialisation
      // otherwise we call it from $watchCollection to prevent duplication of slider instances
      if (!collectionKey) {
        renewSlider();
      }

      scope.$on('$destroy', destroySlider);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
