import contentBuffer from '../../app/service/domain/content-buffer.js';

(function () {

    'use strict';

    const directive = { name: 'betiniaCashboxFrameAmount' };

    controller.$inject = [ 'contentBuffer'  ];

    function controller( _contentBuffer ){

        function link( scope, element, attrs ){

            const pc = _contentBuffer.read( 'popup' );
            if( pc.amount ){
                scope.formData.amount = pc.amount;
            }

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
