import '../../../../service/helper.js';
import '../../../../service/domain/user.js';
import '../../../../service/dk/dk_limits.js';
(function () {
  'use strict';

  const component = { name: 'denmarkProfileLossesLimitMonth' };
  const group = 'losses';
  const type = 'month';

  controller.$inject = ['$scope', 'helper', 'user', 'dkLimits'];

  function controller($scope, _helper, _user, _dkLimits) {
    $scope.preloader = false;

    $scope.formData = {
      amount: _user.profile.limits[group][type].amount,
    };

    $scope.limit = _user.profile.limits[group][type];

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      const o = {
        group,
        type,
        amount: parseInt($scope.formData.amount),
      };
      _dkLimits.moneyLimit
        .set(o)
        .then((a) => {
          $scope.formData.amount = a.data.result.limits[group][type].amount;
          $scope.limit = a.data.result.limits[group][type];
        })
        .catch((e) => {
          $scope.formData.amount = _user.profile.limits[group][type].amount;
          $scope.limit = _user.profile.limits[group][type];
        })
        .finally(() => {
          $scope.preloader = false;
        });
    });

    $scope.cancel = () => {
      $scope.preloader = true;
      const o = {
        group,
        type,
      };
      _dkLimits.moneyLimit
        .cancel(o)
        .then((a) => {
          $scope.formData.amount = a.data.result.limits[group][type].amount;
          $scope.limit = a.data.result.limits[group][type];
        })
        .catch((e) => {
          $scope.formData.amount = _user.profile.limits[group][type].amount;
          $scope.limit = _user.profile.limits[group][type];
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
