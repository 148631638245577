import user from '../../app/service/domain/user.js';
import popup from '../../app/service/domain/popup.js';
import paysystem from '../../app/service/domain/paysystem.js';

(function () {

    'use strict';

    const directive = { name: 'betiniaPaymentsBtn' };

    controller.$inject = [ 'user' , 'popup', 'paysystem' ];

    function controller( _user , _popup, _paysystem ){

        let payItemsName = [];

        _paysystem
            .banking({})
            .then(a => {payItemsName = a.result.map(item => item.name)})

        function link( scope, element, attrs ){

            const handlerClick = () => {
                if( _user.status){
                    const type = scope.type === 'deposit' ? 1 : 2;
                    const step = (payItemsName.includes(scope.item.name) && scope.$parent.$parent.$parent.currency === _user.profile.currency && scope.type === 'deposit') ? 2 : 1;
                    const paySystem = (payItemsName.includes(scope.item.name) && scope.$parent.$parent.$parent.currency === _user.profile.currency && scope.type === 'deposit') ? scope.item : '';
                    _popup.open({
                        name:'cashbox',
                        content : {
                            'tab1' : type,
                            'tab2' : step,
                            'paySystem' : paySystem }
                    });

                } else {
                    if( Boolean(localStorage.getItem( 'rememberedLogin' )) ){
                        _popup.open({name:'login'});
                    }else{
                        _popup.open({name:'registration'});
                    }
                }
            }

            element[0].addEventListener( 'click', handlerClick);

            scope.$onDestroy = () => {
                element[0].removeEventListener( 'click', handlerClick);
            };

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
