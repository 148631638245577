import { paynplay } from '../../../app/service/trustly/paynplay.js';
import modal from '../../../app/service/domain/modal.js'
import trustly$ from '../../../app/service/rx/trustly$.js'
import state from '../../../app/service/domain/state.js'
import config from '../../../app/service/configs/config.js'
import user from '../../../app/service/domain/user.js'

(function () {
  'use strict';

  const component = {
    name: 'mitidSuccess',
  };

  controller.$inject = [
    'paynplay',
    'modal',
    'state',
    '$state',
    'config',
    'user',
  ];

  function controller(
    _paynplay,
    _modal,
    _state,
    _$state,
    _config,
    _user,
  ) {
    const {
      error,
      error_description,
      state,
      code,
      scope,
      transaction_id,
    } = _$state.params;

    const initialParams = {
      order_id: state,
      paymethod: 'devcode-mitid',
      token: code,
    }

    function auth(options) {
      _paynplay
        .auth(options)
        .then(({ result }) => {
          if (!_user.profile.is_profile_complete) {
            authorised(result);
          } else {
            success();
          }
        })
        .catch(err => {
          console.log('Error ::: ', err);
        });
    }
    function authorised(result) {
      console.log('Result ::: ', result);
    }

    function removeOrderIdFromLs() {
      localStorage.removeItem('orderId');
    }

    function success() {
      removeOrderIdFromLs();
      _state.goto('home'); // need to remove redirect to home
    }

    if (!error) {
      auth(initialParams)
    }
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {}
  });
})();
