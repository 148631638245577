import '../../../service/domain/sport.js'
import '../../../service/rx/ws$.js'
import {filter} from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'sport', 'ws$'];

  function controller($scope, _sport, _ws$) {
    $scope.collection = [];

    this.$onChanges = () => {
      $scope.preloader = true;

      const options = {
        count: this.count || 10,
      };

      if (this.category) options.category = this.category;

      _sport
        .feed(options)
        .then((a) => {
          $scope.collection = a.result;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    _ws$
      .pipe(filter((item) => item.event === 'sport_event_feed'))
      .subscribe((message) => {
        $scope.$apply(() => ($scope.collection = message.data));
      });
  }

  const bindings = {
    count: '<',
    category: '<',
  };

  new Controller('sportsFeedList', controller, bindings);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportsFeedList${i}`, 'sportsFeedList');
  });
})();
