import '../../../service/dk/dk_limits.js';
import '../../../service/helper.js';
import '../../../service/domain/state.js';
(function () {
  'use strict';

  const component = { name: 'denmarkInitialLimitForm' };

  const group = 'deposit';

  controller.$inject = ['$scope', 'dkLimits', 'helper', 'state'];

  function controller($scope, _dkLimits, _helper, _state) {
    $scope.preloader = false;

    $scope.formData = [
      { type: 'day', group, amount: 0 },
      { type: 'week', group, amount: 0 },
      { type: 'month', group, amount: 0 },
    ];

    $scope.submit = _helper.form.validator((form) => {
      const data = $scope.formData.filter((limit) => limit.amount > 0);

      //- перевірка на встановлення хоча б одного ліміта
      if (data.length) {
        $scope.preloader = true;

        _dkLimits.multiLimits
          .set(data)
          .then((a) => {
            _state.goback();
          })
          .catch((e) => {})
          .finally(() => {
            $scope.preloader = false;
          });
      }
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
