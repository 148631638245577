import '../../service/domain/user.js';
import '../../service/domain/http.js';
import '../../service/configs/config.js';
import '../../service/configs/analytic-events.js';
import '../analytics/heatmap.js'
(function () {
  'use strict';

  controller.$inject = ['user', 'http', '$q', 'config', 'analyticsEvents', 'heatmap'];

  function controller(_user, _http, $q, _config, _analyticsEvents, _heatmap) {
    return {
      register(credentials) {
        const defer = $q.defer(),
          alias = 'userRegister',
          config = {
            meta: { alias },
          },
          ls = JSON.parse(window.localStorage.getItem('params') || '{}'),
          data = Object.assign({}, ls, credentials);

        data.product = _config.product;

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
            analytics.send(_analyticsEvents.registrationSuccess, 'success', answer.data.result.login);
            _heatmap.sendEvent('event', 'register');
            _heatmap.sendEvent('identify', _user.profile.account_id);
          },
          (answer) => {
            defer.reject(answer.data);
            analytics.send(_analyticsEvents.registrationFail, answer.data.messages[0].code?.toString(), 'Not_Login');
          }
        );

        return defer.promise;
      },

      login(data) {
        const defer = $q.defer(),
          alias = 'userLogin',
          config = {
            meta: { alias },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
            analytics.send(_analyticsEvents.loginSuccess, 'success', data.login);
            _heatmap.sendEvent('event', 'login');
            _heatmap.sendEvent('identify', _user.profile.account_id);
          },
          (answer) => {
            defer.reject(answer.data);
            analytics.send(_analyticsEvents.loginFail, answer.data.messages[0].code?.toString(), 'Not_Login');
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('dk_account', controller);
})();
