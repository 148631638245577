//API doc: https://wiki.araxio.tech/display/GD/Playgrounds+API
import config from "../../app/service/configs/config.js";
import popup from "../../app/service/domain/popup.js";
import modal from "../../app/service/domain/modal.js";
import playgrounds from "../../app/service/gamification/playgrounds.js";
import ws$ from "../../app/service/rx/ws$.js";
import { gsap, Power2 } from 'gsap';
import * as THREE from 'three';
import {MapControls} from 'three/examples/jsm/controls/MapControls.js'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

(function () {
  'use strict';

  const component = {
    name: 'betiniaPlaygrounds',
  };

  controller.$inject = ['$scope', '$element', '$timeout', 'config', 'popup', 'modal', 'playgrounds', 'ws$'];

  function controller($scope, $element, $timeout, _config, _popup, _modal, _playgrounds, _ws$) {
    const CITY_STATUS = ['completed', 'in_progress'];
    const PLAYGROUND_STATUS = {
      locked: 'locked',
      completed: 'completed',
    };
    const MAP_DIMENSIONS = {
      width: 1920,
      height: 905,
    };
    const HEADER_HEIGHT = 62;
    const dvsAlert = document.querySelector('dvs-alert');
    const COORDS = {
      copenhagen: {
        volleyball: {
          x: 870,
          y: 260,
        },
        hockey: {
          x: 495,
          y: 390,
        },
        basketball: {
          x: 1300,
          y: 375,
        },
        tennis: {
          x: 1430,
          y: 710,
        },
        football: {
          x: 765,
          y: 600,
        },
      },
      berlin: {
        'table-tennis': {
          x: 435,
          y: 275,
        },
        hockey: {
          x: 950,
          y: 600,
        },
        basketball: {
          x: 1470,
          y: 634,
        },
        tennis: {
          x: 1288,
          y: 333,
        },
        football: {
          x: 890,
          y: 339,
        },
      },
      warsaw: {
        volleyball: {
          x: 1322,
          y: 184,
        },
        'table-tennis': {
          x: 1235,
          y: 418,
        },
        hockey: {
          x: 957,
          y: 313,
        },
        basketball: {
          x: 1196,
          y: 756,
        },
        tennis: {
          x: 474,
          y: 278,
        },
        football: {
          x: 636,
          y: 651,
        },
      },
      rome: {
        football: {
          x: 820,
          y: 177,
        },
        football2: {
          x: 1458,
          y: 674,
        },
        volleyball: {
          x: 1095,
          y: 653,
        },
        'table-tennis': {
          x: 900,
          y: 464,
        },
        hockey: {
          x: 1463,
          y: 368,
        },
        basketball: {
          x: 565,
          y: 298,
        },
        tennis: {
          x: 1116,
          y: 350,
        },
      },
      betinia: {
        'tennis-table': {
          x: 483,
          y: 205,
        },
        'motor-racing': {
          x: 965,
          y: 296,
        },
        'horse-racing': {
          x: 704,
          y: 431,
        },
        'dogs-racing': {
          x: 1489,
          y: 695,
        },
        baseball: {
          x: 483,
          y: 736,
        },
        tennis: {
          x: 1506,
          y: 200,
        },
        basketball: {
          x: 980,
          y: 685,
        },
        football: {
          x: 1340,
          y: 457,
        },
      },
    };

    const LEVEL_COLORS = [new THREE.Vector3(0.31, 1.0, 0.58), new THREE.Vector3(0.96, 1.0, 0.0), new THREE.Vector3(1.0, 0.58, 0.0)];
    //-todo
    const LOCALES = 'da-DK';
    if (!window.localStorage.getItem('playgrounds-viewed')) {
      _modal.open('playgrounds-first-view').then(() => {
        document.body.classList.add('is-modal-open');
        window.localStorage.setItem('playgrounds-viewed', 'true');
      });
    }

    let mode = '';
    const canvasElement = document.querySelector('.playground');
    const mapElement = document.querySelector('.playground-map');
    const mapOverlay = document.querySelector('.playground-overlay');
    const $fireworks = document.querySelector('.playground-fireworks');

    const pinBg = new THREE.TextureLoader().load('./img/assets/pin.png');

    const animWrap = document.querySelector('.playground-overlay__anim-wrap');
    const img = document.createElement('img');

    let IS_MAP_DISABLED = false;

    const MAP_BG_RATIO = MAP_DIMENSIONS.width / MAP_DIMENSIONS.height;
    const PIN_DIMENSTIONS = { width: 45, height: 59 };

    let canvasDimenstions = canvasElement.getBoundingClientRect();
    let camera, scene, renderer, minPan, maxPan;
    let mapPlane, mapGeometry, mapTextures;
    let playgroundPlanes = [],
      playgroundPins = [],
      pinVideos = [];

    let INTERSECTED;

    let controls, mouse, raycaster;

    let _v = new THREE.Vector3();

    const scrollAnimation = {
      opacity: 0,
      x: 0,
      y: 0,
      width: canvasDimenstions.height * MAP_BG_RATIO,
      height: canvasDimenstions.height,
    };

    $scope.fireworks = $fireworks;
    $scope.preloader = true;
    $scope.preloaderBuy = false;
    $scope.collection = [];
    $scope.city = {};
    $scope.playground = {};

    $scope.openCity = function (index) {
      if ($scope.collection[index].status === PLAYGROUND_STATUS.locked) {
        return false;
      }

      $scope.loading = true;
      $scope.playground = {};

      const prevIndex =
        $scope.city && Object.keys($scope.city).length === 0 && Object.getPrototypeOf($scope.city) === Object.prototype
          ? index
          : $scope.city.team_order - 1;

      $scope.city = $scope.collection[index];

      normalizePlaygroundCoordinates(() => {
        clearScene();
        addMapPlane(mapTextures[prevIndex], mapTextures[index], () => {
          addPlaygroundPlanes();
          $scope.loading = false;
        });
      });
    };

    $scope.clearPlaygroundImg = function() {
      animWrap.innerHTML = '';
    }

    $scope.open = function (alias) {
      if ($scope.playground.alias) {
        $scope.playground.justBought = false;
        $scope.playground = {};
      }

      if ($scope.fireworks) {
        $scope.fireworks.style.display = 'none';
      }

      const selectedPlayground = $scope.city.list.find((item) => item.alias === alias);

      $scope.preloaderBuy = false;
      $scope.playground = selectedPlayground;

      canvasElement.classList.add('linda-has-open-playground');
      canvasElement.classList.add(`is-${$scope.playground.alias}`);

      const tl = gsap.timeline();

      tl.to(scrollAnimation, {
        duration: 0.75,
        opacity: 1,
        x: selectedPlayground.x + window.innerWidth / 4,
        y: selectedPlayground.y - window.innerHeight / 4,
        width: (canvasDimenstions.height * MAP_BG_RATIO) / 2,
        height: canvasDimenstions.height / 2,
        ease: Power2.easeInOut,
        onStart: () => {
          IS_MAP_DISABLED = true;
          mapOverlay.style.visibility = 'visible';
          camera.clearViewOffset();
          camera.updateProjectionMatrix();
        },
        onUpdate: () => {
          camera.setViewOffset(
            canvasDimenstions.height * MAP_BG_RATIO,
            canvasDimenstions.height,
            scrollAnimation.x,
            -1 * scrollAnimation.y,
            scrollAnimation.width,
            scrollAnimation.height
          );
          mapOverlay.style.opacity = 1;
          camera.updateProjectionMatrix();
        },
        onComplete: () => {
          const state = $scope.level === 1 && $scope.playground.status === 'closed' ? 'closed' : 'opened';
          const status = $scope.playground.status === 'closed' && $scope.level > 1 ? $scope.level - 1 : $scope.level;
          img.className = 'playground-overlay__img';
          img.setAttribute('src', `${_config.cdn}/betinia/cities/${$scope.city.alias}/${status}/${$scope.playground.alias}_${state}.png`);
          img.setAttribute('alt', $scope.playground.title);

          animWrap.appendChild(img);
        }
      });
    };

    $scope.closePlayground = function () {
      $scope.playground.justBought = false;
      canvasElement.classList.remove('linda-has-open-playground');
      canvasElement.classList.remove(`is-${$scope.playground.alias}`);

      $scope.preloaderBuy = false;
      $scope.playground = {};

      const tl = gsap.timeline();

      tl.to(scrollAnimation, {
        duration: 0.75,
        opacity: 0,
        x: 0,
        y: 0,
        width: canvasDimenstions.height * MAP_BG_RATIO,
        height: canvasDimenstions.height,
        ease: Power2.easeInOut,
        onUpdate: () => {
          camera.setViewOffset(
            canvasDimenstions.height * MAP_BG_RATIO,
            canvasDimenstions.height,
            scrollAnimation.x,
            -1 * scrollAnimation.y,
            scrollAnimation.width,
            scrollAnimation.height
          );
          mapOverlay.style.opacity = 0;
          camera.updateProjectionMatrix();
        },
        onComplete: () => {
          $scope.clearPlaygroundImg();
          mapOverlay.style.visibility = 'hidden';
          IS_MAP_DISABLED = false;
          camera.clearViewOffset();
          camera.updateProjectionMatrix();
        },
      });
    };

    $scope.buy = function () {
      if ($scope.playground.status === 'opened' || $scope.playground.status === 'bonus_received') {
        return false;
      }

      $scope.preloaderBuy = true;

      _playgrounds.buy({ id: $scope.playground.id }).then((data) => {
        const index = $scope.collection.findIndex((i) => i.alias === $scope.city.alias) + 1;

        $scope.city.status = data.result.city_status;
        $scope.playground.status = data.result.playground_status;
        $scope.playground.prize = data.result.prize;
        $scope.playground.justBought = true;

        const pic = document.createElement('picture');

        pic.className = 'playground-overlay__img is-top';
        pic.innerHTML = `
          <source srcset="${_config.cdn}/betinia/cities/${$scope.city.alias}/${$scope.level}/${$scope.playground.alias}.webp" type="image/webp" />
          <img src="${_config.cdn}/betinia/cities/${$scope.city.alias}/${$scope.level}/${$scope.playground.alias}.gif" alt="${$scope.playground.title}" />
        `;

        animWrap.prepend(pic);

        new THREE.TextureLoader().load(
          `${_config.cdn}/betinia/playgrounds/${mode}/l${$scope.level}/${$scope.city.alias}/img/${$scope.playground.alias}.png`,
          (playgroundTexture) => {
            addPlayground(playgroundTexture);
            scene.remove(playgroundPins[$scope.playground.index]);
            addPlaygroundPin($scope.playground);
          }
        );

        if (data.result.city_status === 'reward_taken') {
          $scope.playground.justBought = false;

          if ($scope.collection.some((c) => c.status !== 'reward_taken')) {
            _modal
              .open('playground-city-reward', {
                isLast: false,
                city: $scope.collection[index],
                prev_city: $scope.collection[index - 1],
                level: $scope.level,
              })
              .then(() => {
                $scope.closePlayground();
                loadPlaygrounds(false);
              });
          } else if ($scope.level === 3) {
            _modal
              .open('playground-city-reward', {
                isLast: true,
                city: $scope.collection[index - 1],
                prev_city: $scope.collection[index - 1],
                level: $scope.level,
              })
              .then(() => {
                $scope.closePlayground();
                loadPlaygrounds(false, true);
              });
          } else {
            _modal
              .open('playground-new-level', {
                city: $scope.collection[index - 1],
                level: $scope.level,
              })
              .then(() => {
                $scope.closePlayground();
                removeListeners();
                loadPlaygrounds();
              });
          }
        }
      });
    };

    $scope.nextPlayground = function () {
      const currentCity = $scope.collection.find((s) => s.status === 'in_progress');
      const currentCityIndex = $scope.collection.findIndex((s) => s.status === 'in_progress');
      const minCostPlayground = currentCity.list
        .filter((c) => c.status === 'closed')
        .sort((a, b) => a.price - b.price)[0];

      if ($scope.city.alias !== currentCity.alias && currentCityIndex) {
        $scope.openCity(currentCityIndex);
      }

      $scope.playground.justBought = false;
      $scope.playground = {};
      $scope.preloaderBuy = false;
      $scope.open(minCostPlayground.alias);
    };

    this.$onInit = () => {
      mode = this.mode;

      loadPlaygrounds();
    };

    if (!window.localStorage.getItem('playgrounds-viewed')) {
      _modal.open('playgrounds-first-view').then(() => {
        window.localStorage.setItem('playgrounds-viewed', 'true');
      });
    }

    function normalizePlaygroundCoordinates(callback) {
      const k = canvasDimenstions.height / MAP_DIMENSIONS.height;

      $timeout(() => {
        $scope.city.list.forEach((item) => {
          item.x = COORDS[$scope.city.alias][item.alias].x * k - (canvasDimenstions.height * MAP_BG_RATIO) / 2;
          item.y =
            -COORDS[$scope.city.alias][item.alias].y * k + canvasDimenstions.height / 2 + PIN_DIMENSTIONS.height;
        });
        callback();
      }, 0);
    }

    function loadTextures(level, collection, callback) {
      const loader = new THREE.TextureLoader();
      const textures = collection.map((item) =>
        loader.load(`${_config.cdn}/betinia/playgrounds/${mode}/l${level - 1}/${item.alias}/img/map.jpg`)
      );

      const texture = Promise.all(textures, (resolve, reject) => {
        resolve(texture);
      }).then((result) => {
        mapTextures = result;
        callback();
      });
    }

    function loadPlaygrounds(reload = true, isLast = false) {
      _playgrounds.collection().then(({ result: { current_level, list } }) => {

        console.log(current_level, list);

        $scope.collection = list;
        $scope.level = current_level;

        let index = $scope.collection.findIndex((i) => CITY_STATUS.includes(i.status));

        if (reload) {
          removeScene();
          createScene();
          animate();

          loadTextures($scope.level, $scope.collection, () => {
            $scope.openCity(index !== -1 ? index : $scope.collection.length - 1);
          });

          window.addEventListener('mousemove', onMouseMove, false);
          window.addEventListener('mousedown', onClick, false);
          window.addEventListener('resize', resizeScene, false);
        } else {
          $scope.openCity(index !== -1 ? index : $scope.collection.length - 1);
        }

        if (isLast) {
          $scope.openCity(0);
        }
      });

      return this;
    }

    function createScene() {
      scene = new THREE.Scene();
      camera = new THREE.OrthographicCamera(
        canvasDimenstions.width / -2,
        canvasDimenstions.width / 2,
        canvasDimenstions.height / 2,
        canvasDimenstions.height / -2,
        1,
        1000
      );

      camera.position.set(0, 0, 100);

      scene.add(camera);

      renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(canvasDimenstions.width, canvasDimenstions.height);

      controls = new MapControls(camera, renderer.domElement);
      // CONTROLS INTERTIA
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;

      controls.enableRotate = false;
      controls.screenSpacePanning = false;
      controls.minZoom = 1;
      controls.maxZoom = 1;
      controls.enableRotate = false;

      // minmax left/right side panning
      const distanceX = canvasDimenstions.width > canvasDimenstions.height * MAP_BG_RATIO ? 0 : Math.abs(window.innerWidth - canvasDimenstions.height * MAP_BG_RATIO) / 2;

      minPan = new THREE.Vector3(-distanceX, 0, 0);
      maxPan = new THREE.Vector3(distanceX, 0, 0);

      controls.addEventListener('change', function () {
        _v.copy(controls.target);
        controls.target.clamp(minPan, maxPan);
        _v.sub(controls.target);
        camera.position.sub(_v);
        scalePlaygroundPin(1 / camera.zoom);
      });

      // use ambient/directional light instead if we won't use phong material
      const light = new THREE.SpotLight(0xffffff);
      light.position.set(-50, 150, 250);
      light.angle = 0.33;
      light.penumbra = 0.9;

      scene.add(light);

      raycaster = new THREE.Raycaster();

      mapElement.appendChild(renderer.domElement);
      renderer.render(scene, camera);
    }

    function removeScene() {
      if (!renderer) return;
      mapElement.removeChild(renderer.domElement);
    }

    function clearScene() {
      if (!scene) return;
      scene.remove(mapPlane);

      playgroundPlanes.forEach((plane) => scene.remove(plane));
      playgroundPlanes = [];

      playgroundPins.forEach((pin) => scene.remove(pin));
      playgroundPins = [];

      pinVideos.forEach((video) => scene.remove(video));
      pinVideos = [];
    }

    function animate() {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    }

    function addMapPlane(prevCityTex, nextCityTex, callback) {
      mapGeometry = new THREE.PlaneGeometry(canvasDimenstions.height * MAP_BG_RATIO, canvasDimenstions.height);

      // --- ADDING MAP IMAGE AS TEXTURE
      const mapUniforms = {
        transition: { type: 'f', value: 0.0 },
        texture1: { type: 't', value: prevCityTex },
        texture2: { type: 't', value: nextCityTex },
      };

      const mapMaterial = new THREE.ShaderMaterial({
        uniforms: mapUniforms,
        vertexShader: document.getElementById('vertexShaderLoader').textContent,
        fragmentShader: document.getElementById('fragmentShaderMap').textContent,
      });

      mapMaterial.transparent = true;

      mapPlane = new THREE.Mesh(mapGeometry, mapMaterial);
      scene.add(mapPlane);
      camera.updateProjectionMatrix();
      camera.lookAt(mapPlane.position.x, mapPlane.position.y, mapPlane.position.z);

      const tl = gsap.timeline();
      tl.fromTo(
        mapMaterial.uniforms.transition,
        { value: 0.0 },
        {
          duration: 0.3,
          value: 1.0,
          onComplete: callback,
        }
      );
    }

    function addPlaygroundPlanes() {
      $timeout(() => {
        $scope.city.list.forEach((item) => {

          addPlaygroundPin(item);

          if (item.status !== 'closed') {
            new THREE.TextureLoader().load(
              `${_config.cdn}/betinia/playgrounds/${mode}/l${$scope.level}/${$scope.city.alias}/img/${item.alias}.png`,
              (texture) => {
                addPlayground(texture);
                $scope.preloader = false;
              }
            );
          } else {
            // todo: quick fix, needs refactoring
            window.setTimeout(() => {
              $scope.preloader = false;
            }, 1500);
          }
        });
      }, 0);
    }

    function addPlaygroundPin(item) {
      const textContent = item.status === 'closed' ? item.price.toLocaleString(LOCALES) : 'done';
      const textSize = item.status === 'closed' ? 12 : 10;
      const textPadding = item.status === 'closed' ? 7 : 8;
      const textColor = item.status === 'closed' ? '#FFFFFF' : 'rgb(10, 242, 70)';
      const borderColor =
        item.status === 'closed' ? new THREE.Vector3(1.0, 1.0, 1.0) : new THREE.Vector3(0.66, 0.0, 1.0);

      const text = createTextCanvas(textContent, {
        fontSize: textSize,
        color: textColor,
        paddingTop: textPadding,
      });

      const video = document.querySelector(`.playground-icon.is-${item.alias}`);
      video.play();

      addPin(pinBg, video, text, borderColor, { x: item.x, y: item.y });
    }

    function addPlayground(playgroundTexture, callback) {
      const uniformsMove = {
        transition: { type: 'f', value: 0.0 },
        texture1: { type: 't', value: playgroundTexture },
      };

      const playgroundMaterial = new THREE.ShaderMaterial({
        uniforms: uniformsMove,
        vertexShader: document.getElementById('vertexShaderLoader').textContent,
        fragmentShader: document.getElementById('fragmentShaderLoader').textContent,
      });

      playgroundMaterial.transparent = true;

      const playgroundGeometry = new THREE.PlaneBufferGeometry(
        canvasDimenstions.height * MAP_BG_RATIO,
        canvasDimenstions.height
      );
      const playgroundPlane = new THREE.Mesh(playgroundGeometry, playgroundMaterial);

      const tl = gsap.timeline();
      tl.fromTo(
        playgroundMaterial.uniforms.transition,
        { value: 0.0 },
        { duration: 0.3, value: 1.0, onComplete: callback }
      );

      playgroundPlanes.push(playgroundPlane);
      scene.add(playgroundPlane);
    }

    function addPin(texture1, texture2, texture3, borderColor, position) {
      const videoTexture = new THREE.VideoTexture(texture2);
      videoTexture.needsUpdate = true;

      let textTexture = new THREE.CanvasTexture(texture3);

      const uniforms = {
        color: { type: 'v3', value: borderColor },
        texture1: { type: 't', value: texture1 },
        texture2: { type: 't', value: videoTexture },
        texture3: { type: 't', value: textTexture },
      };

      const pinMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: document.getElementById('vertexShaderLoader').textContent,
        fragmentShader: document.getElementById('fragmentShaderPin').textContent,
      });

      pinMaterial.transparent = true;

      const pinGeometry = new THREE.PlaneGeometry(PIN_DIMENSTIONS.width, PIN_DIMENSTIONS.height);
      const pinPlane = new THREE.Mesh(pinGeometry, pinMaterial);

      pinPlane.position.x = position.x;
      pinPlane.position.y = position.y;
      pinPlane.position.z = 5;

      playgroundPins.push(pinPlane);
      scene.add(pinPlane);
    }

    function createTextCanvas(text, parameters = {}) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const fontSize = parameters.fontSize * 11.33;
      ctx.font = `bold ${fontSize}px Roboto`;

      const textHeight = fontSize;
      const width = 512;
      const height = 671;
      const paddingTop = (671 * parameters.paddingTop) / 59;

      // Resize canvas to match text size
      canvas.width = width;
      canvas.height = height;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';

      // Re-apply font since canvas is resized.
      ctx.font = `bold ${fontSize}px Roboto`;
      ctx.textAlign = parameters.align || 'center';
      ctx.textBaseline = parameters.baseline || 'middle';

      // Make the canvas transparent for simplicity
      ctx.fillStyle = 'transparent';
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      ctx.fillStyle = parameters.color || 'white';
      ctx.fillText(text.toUpperCase(), width / 2, paddingTop + textHeight / 2);

      return canvas;
    }

    function scalePlaygroundPin(scaleIndex) {
      playgroundPins.forEach((pin) => {
        pin.scale.set(scaleIndex, scaleIndex, scaleIndex);
      });
      pinVideos.forEach((video) => {
        video.scale.set(scaleIndex, scaleIndex, scaleIndex);
      });
    }

    // MOUSEMOVE EVENT
    function onMouseMove(event) {
      const FULL_HEADER_HEIGHT = HEADER_HEIGHT + dvsAlert.offsetHeight;

      if (!camera) return;
      if (IS_MAP_DISABLED) {
        document.body.style.cursor = 'default';

        if (INTERSECTED) INTERSECTED.material.uniforms.color.value = INTERSECTED.currentHex;
        INTERSECTED = null;

        return;
      }

      mouse = new THREE.Vector2();
      mouse.x = (event.clientX / canvasDimenstions.width) * 2 - 1;
      mouse.y = -((event.clientY - FULL_HEADER_HEIGHT) / canvasDimenstions.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(playgroundPins);

      if (intersects.length > 0) {
        document.body.style.cursor = 'pointer';

        if (intersects[0].object != INTERSECTED) {
          if (INTERSECTED) INTERSECTED.material.uniforms.color.value = INTERSECTED.currentHex;
          INTERSECTED = intersects[0].object;
          INTERSECTED.currentHex = INTERSECTED.material.uniforms.color.value;
          INTERSECTED.material.uniforms.color.value = LEVEL_COLORS[$scope.level - 1];
        }
      } else {
        document.body.style.cursor = 'default';

        if (INTERSECTED) INTERSECTED.material.uniforms.color.value = INTERSECTED.currentHex;
        INTERSECTED = null;
      }
    }

    function onClick() {
      if (INTERSECTED) {
        const clickedIndex = playgroundPins.findIndex((el) => el === INTERSECTED);
        const clickedPlayground = $scope.city.list[clickedIndex];
        $scope.open(clickedPlayground.alias);
      }
    }

    // RESIZE EVENT
    function resizeScene() {
      canvasDimenstions = canvasElement.getBoundingClientRect();

      const newMapGeometry = new THREE.PlaneGeometry(canvasDimenstions.height * MAP_BG_RATIO, canvasDimenstions.height);
      mapPlane.geometry.dispose();
      mapPlane.geometry = newMapGeometry;

      playgroundPlanes.map((plane) => {
        plane.geometry.dispose();
        plane.geometry = newMapGeometry;
      });

      normalizePlaygroundCoordinates(() => {
        playgroundPins.map((pin, index) => {
          if (!$scope.city.list[index]) {
            return;
          }
          pin.position.x = $scope.city.list[index].x;
          pin.position.y = $scope.city.list[index].y;
        });
      });

      camera.left = canvasDimenstions.width / -2;
      camera.right = canvasDimenstions.width / 2;
      camera.top = canvasDimenstions.height / 2;
      camera.bottom = canvasDimenstions.height / -2;

      camera.updateProjectionMatrix();
      camera.lookAt(mapPlane.position.x, mapPlane.position.y, mapPlane.position.z);

      const distanceX = canvasDimenstions.width > canvasDimenstions.height * MAP_BG_RATIO ? 0 : Math.abs(window.innerWidth - canvasDimenstions.height * MAP_BG_RATIO) / 2;

      minPan = new THREE.Vector3(-distanceX, 0, 0);
      maxPan = new THREE.Vector3(distanceX, 0, 0);

      renderer.setSize(canvasDimenstions.width, canvasDimenstions.height);
    }

    function removeListeners() {
      window.removeEventListener('resize', resizeScene);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mousedown', onClick, false);
    }

    this.$onDestroy = () => {
      removeListeners();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      mode: '<',
    },
  });
})();
