import '../../service/domain/user.js';
import '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['user', 'http', '$q'];

  function controller(_user, _http, $q) {
    const aliasLimitSet = 'userChangeLimit',
      aliasLimitCancel = 'userRemoveLimit';
    /**
     *
     *  UNITS - часовий період (session limit, reality check)
     *
     *  '1': 'minutes';
     *  '2': 'hours';
     *  '3': 'days';
     *  '4': 'weeks';
     *  '5': 'months';
     *  '6': 'years';
     *  '7': 'permanent';
     *
     */

    return {
      // deposit, wager, loss limits
      moneyLimit: {
        set(params) {
          const o = {
            group: params.group,
            type: params.type,
            amount: params.amount,
          };

          const defer = $q.defer();

          _http
            .put(aliasLimitSet, o, { meta: { alias: aliasLimitSet } })
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },

        //- не доступно для deposit лімітів
        cancel(params) {
          const o = {
            group: params.group,
            unit: params.type,
          };

          const defer = $q.defer(),
            config = { meta: { alias: aliasLimitCancel } };

          _http
            .post(aliasLimitCancel, o, config)
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },
      },
      realityCheck: {
        set(params) {
          const o = {
            reality_check: `${params.unit}${params.reality_check}`,
          };

          const defer = $q.defer(),
            alias = 'userDKRealitySettings';

          _http
            .put(alias, o, { meta: { alias } })
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },
      },

      sessionLimit: {
        set(params) {
          /**
           * session_limit - string representation of period.
           * First sign corresponds to period(1-minutes, 2-hours).
           * Next signs are quantity.
           *
           **/
          const o = {
            session_limit: `${params.unit}${params.session_limit}`,
          };

          const defer = $q.defer(),
            alias = 'sessionLength';

          _http
            .put(alias, o, { meta: { alias } })
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },

        cancel() {
          const o = {
            group: 'session_limit',
          };

          const defer = $q.defer(),
            config = { meta: { alias: aliasLimitCancel } };

          _http
            .post(aliasLimitCancel, o, config)
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },
      },
      multiLimits: {
        // приймає масив об'єктів із параметрами для ліміта визначеного типу
        set(data) {
          const defer = $q.defer(),
            alias = 'userMultiLimits';

          _http
            .put(alias, data, { meta: { alias } })
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },
      },

      coolingOff: {
        set(params) {
          const o = {
            unit: params.unit,
            amount: params.amount,
          };

          const defer = $q.defer(),
            alias = 'userDKCoolingOff';

          _http
            .put(alias, o, { meta: { alias } })
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },

        cancel() {
          const o = {
            group: 'cool_off',
          };

          const defer = $q.defer();

          _http
            .post(aliasLimitCancel, o, { meta: { alias: aliasLimitCancel } })
            .then((answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },
      },

      exclude: {
        set(params) {
          const o = {};

          switch (params.action) {
            case 'EXCLUDE':
              o.password = params.password;
              break;
            default:
              o.frozen_to = `${params.unit}${params.frozen_to}`;
              break;
          }

          const defer = $q.defer(),
            alias = 'userExclusion';

          _http
            .post(alias, o, { meta: { alias } })
            .then((answer) => {
              _user.profile = {};
              defer.resolve(answer);
            })
            .catch((error) => {
              defer.reject(error);
            });

          return defer.promise;
        },
      },
    };
  }

  app.factory('dkLimits', controller);
})();
