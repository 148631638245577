(function () {
  'use strict';

  const langs = {
    "en": "en-US",
    "ar": "ar-AB",
    "ca": "ca-ES",
    "zh": "zh-CN",
    "zh": "zh-HK",
    "zh": "zh-TW",
    "hr": "hr-HR",
    "cs": "cs-CZ",
    "dk": "da-DK",
    "de": "de-DE",
    "nl": "nl-NL",
    "en": "en-AU",
    "en": "en-GB",
    "fi": "fi-FI",
    "fr": "fr-CA",
    "fr": "fr-FR",
    "de": "de-DE",
    "el": "el-GR",
    "he": "he-IL",
    "hi": "hi-IN",
    "hu": "hu-HU",
    "id": "id-ID",
    "it": "it-IT",
    "ja": "ja-JP",
    "ko": "ko-KR",
    "ms": "ms-MY",
    "nb": "nb-NO",
    "pl": "pl-PL",
    "pt": "pt-BR",
    "pt": "pt-PT",
    "ro": "ro-RO",
    "ru": "ru-RU",
    "sk": "sk-SK",
    "es": "es-MX",
    "es": "es-ES",
    "sv": "sv-SE",
    "th": "th-TH",
    "tr": "tr-TR",
    "uk": "uk-UA",
    "vi": "vi-VN"
  }

  app.constant('apayLangReplacements', langs);
})();
