(function () {
  'use strict';

  controller.$inject = ['$scope'];

  function controller($scope) {
    $scope.item = {};
    this.$onChanges = () => {
      $scope.item = this.item || {};
    };
  }

  new Controller('sportsFeedItem', controller, {
    item: '<',
  });
})();

(function () {
  'use strict';
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportsFeedItem${i}`, 'sportsFeedItem');
  });
})();
