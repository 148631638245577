import contentBuffer from '../../app/service/domain/content-buffer.js';
import contentBuffer$ from '../../app/service/rx/content-buffer$.js';

import { filter } from 'rxjs/operators';

(function(){

    'use strict';

    controller.$inject = [ 'contentBuffer', 'contentBuffer$' ];

    function controller( _contentBuffer, _contentBuffer$ ){

        function link( scope ) {

            scope.filter = _contentBuffer.read('guideFilter') || {outTab: '0', inTab: '0'};

            const subscription = _contentBuffer$
                .pipe(filter( data => data.name === 'guideFilter' ))
                .subscribe( () => {
                    scope.filter = _contentBuffer.read('guideFilter');
                });
            scope.$onDestroy = () => {
                subscription.unsubscribe();
            };
        }

        return {
            restrict: 'A',
            link:link
        };

    }

    app.directive( 'betiniaGetGuideFilter', controller );

})();

