import '../../../service/helper.js';
import '../../../service/domain/user.js';
import '../../../service/dk/dk_limits.js';
(function () {
  'use strict';

  const component = { name: 'denmarkProfileCoolingOff' };

  const group = 'cool_off';

  controller.$inject = ['$scope', 'helper', 'user', 'dkLimits'];

  function controller($scope, _helper, _user, _dkLimits) {
    const defaultUnit = 'day';

    $scope.preloader = false;

    $scope.formField = {
      day: {
        min: 1,
        max: 365,
      },
      week: {
        min: 1,
        max: 52,
      },
      month: {
        min: 1,
        max: 12,
      },
      year: {
        min: 1,
        max: 1,
      },
    };

    $scope.formData = {
      unit: defaultUnit,
      amount: 0,
    };

    $scope.limit = {
      unit: defaultUnit,
      amount: 0,
    };

    if (_user.profile[group].amount) {
      $scope.formData.unit = _user.profile[group].unit;
      $scope.formData.amount = _user.profile[group].amount;
      $scope.limit = { ..._user.profile[group] };
    }

    function setLimit(a) {
      if (a.data.result[group].amount) {
        $scope.formData.unit = a.data.result[group].unit;
        $scope.formData.amount = a.data.result[group].amount;
        $scope.limit = { ...a.data.result[group] };
      }
    }

    $scope.submit = _helper.form.validator((form) => {
      const o = {
        unit: $scope.formData.unit,
        amount: parseInt($scope.formData.amount),
      };

      $scope.preloader = true;

      _dkLimits.coolingOff
        .set(o)
        .then((a) => {
          setLimit(a);
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });

    $scope.cancel = () => {
      $scope.preloader = true;

      _dkLimits.coolingOff
        .cancel()
        .then((a) => {
          setLimit(a);
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
