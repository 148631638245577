import '../../../service/domain/banner.js';
import '../../../service/rx/system$.js';
import { filter } from 'rxjs/operators';
(function () {
  controller.$inject = ['$scope', 'banner', 'system$'];

  function controller($scope, _bannerList, _system$) {
    $scope.preloader = false;
    $scope.collection = [];
    let options = {},
      loginSubscription,
      logoutSubscription;

    function getBannerList(options) {
      $scope.preloader = true;
      _bannerList
        .collection(options)
        .then((a) => {
          $scope.collection = a.result;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onInit = () => {
      options = {
        category: this.category || 'banner',
        place: this.place,
        out: this.out || '',
        count: this.count || 25,
      };

      getBannerList(options);

      loginSubscription = _system$
        .pipe(filter((m) => m.action === 'login'))
        .subscribe((m) => {
          options.auth = true;
          getBannerList(options);
        });
      logoutSubscription = _system$
        .pipe(filter((m) => m.action === 'logout'))
        .subscribe((m) => {
          options.auth = false;
          getBannerList(options);
        });
    };

    this.$onDestroy = () => {
      loginSubscription.unsubscribe();
      logoutSubscription.unsubscribe();
    };
  }

  new Controller('banner', controller, {
    place: '<',
    out: '<',
    count: '<',
    category: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanBannerList${i}`, 'banner');
  });
})();
