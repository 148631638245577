import '../../../service/domain/payment.js'
import '../../../service/helper.js'
import '../../../service/domain/user.js'
import '../../../service/domain/state.js'
import '../../../service/configs/config.js'
(function () {
  let wnd = {};

  function makeForm(path, params, el) {
    document.querySelector('#depositForm')?.remove();

    const form = document.createElement('form');

    form.setAttribute('method', 'POST');
    form.setAttribute('action', path);
    form.setAttribute('id', 'depositForm');
    form.setAttribute('target', 'depositWindow');

    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);
        form.appendChild(hiddenField);
      }
    }

    el.append(form);
    form.submit();
  }

  function close(message) {
    if (message.data === 'close') {
      wnd.close();
      wnd = {};
    }

    if (message.data === 'open-chat') {
      wnd.close();
      wnd = {};
      _system$.next({ action: 'open chat' });
    }
  }

  controller.$inject = ['$scope', 'payment', 'helper', '$element', 'user', 'state', 'config'];

  function controller($scope, _payment, _helper, $element, _user, _state, _config) {
    $scope.system = {};
    $scope.preloader = true;
    $scope.formData = { amount: 0 };

    $scope.submit = _helper.form.validator((form) => {
      if ($scope.formData.paymethod === undefined) {
        _state.goto('deposit');
        return false;
      }

      window.onmessage = close;

      if (wnd.focus)
        if (wnd.closed) {
          wnd = {};
        } else {
          wnd.focus();
          return;
        }

      const w = 800,
        h = 500,
        l = (window.innerWidth - w) / 2,
        t = (window.innerHeight - h) / 2,
        checker = setInterval(() => {
          if (wnd.closed) {
            wnd = {};
            clearInterval(checker);
          }
        }, 100);

      wnd = _config.browser.webView
        ? window
        : window.open(
            `/preloader.html?login=${_user.profile.login}`,
            'depositWindow',
            `height=${h},width=${w},left=${l},top=${t}`
          );

      //--- /CAD-343

      $scope.preloader = true;
      _payment
        .deposit($scope.formData)
        .then(({ result }) => {
          $scope.preloader = false;
          if (result.method.toLowerCase() === 'post') {
            makeForm(result.url, result.post, $element);
          } else {
            wnd.document.location = result.url;
          }
        })
        .catch(() => {
          $scope.preloader = false;
          wnd.document.location = `/${_config.currentLang}/payment/fail?login=${_user.profile.login}`;
        })
        .finally();
    });

    $scope.preloader = true;
    _payment
      .lastDep()
      .then(({ result }) => {
        $scope.system = result.paysystem;
        $scope.formData.paymethod = result.paymethod;
        $scope.formData.amount = result.amount;
      })
      .catch((e) => {})
      .finally(() => {
        $scope.preloader = false;
      });
  }

  new Controller('widgetLastDeposit', controller);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanWidgetLastDeposit${i}`, 'widgetLastDeposit');
  });
})();
