(function () {
    'use strict';

    const directive = { name: 'betiniaPromocodeAutofocus' };

    controller.$inject = ['$timeout'];

    function controller(_timeout){
        function focusOnInput() {
            const firstInput = document.querySelector('.popup-wb__input');
            _timeout(() => {
                firstInput.focus();
            }, 0);
        }

        function link( scope, element){
            element[0].addEventListener('click', () => focusOnInput());

            scope.$on('$destroy', () => {
                element[0].removeEventListener('click', () => focusOnInput());
            });
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );
})();
