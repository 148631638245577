const router = [
    {
        name: 'app.root',
        abstract: true,
        template: '<linda-app linda-zendesk ></linda-app>'
    },

    {
        name: 'app.root.l1',
        abstract: true,
        template: '<linda-view-layer-one></linda-view-layer-one>'
    },
    {
        name: 'app.root.l1.home',
        url: '/',
        template: '<linda-homepage to-top-on-state-change></linda-homepage>'
    },
    {
        name: 'app.root.l1.sportpage',
        abstract:true,
        template: '<linda-sport-page to-top-on-state-change></linda-sport-page>'
    },
    {
        name: 'app.root.l1.sportpage.home',
        url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids&page',

        template: '',
        params: {
            page: 'overview'
        }
    },
    {
        name: 'app.root.l1.sportpage.live',
        url: '/live?sportids&catids&champids&tabs&eventid&betslipoddids&page',
        template: '',
        params: {
            page: 'live'
        }
    },

    {
        name: 'app.root.l1.sportpage.virtual',
        url: '/virtual?sportids&catids&champids&tabs&eventid&betslipoddids&page',
        template: '',
        params: {
            page: 'virtual'
        }
    },
    {
        name: 'app.root.l1.c1',
        abstract: true,
        template: '<batman-ui-wrapper6></batman-ui-wrapper6>',
        params: {
            theme: 'live-casino',
            page: 'categories'
        }
    },
    {
        name: 'app.root.l1.c1.livecasino',
        url: '/live-casino',
        template: '<batman-game-hall3 to-top-on-state-change></batman-game-hall3>',
        params: {
            name: 'live-casino',
            page: 'categories',
        }
    },
    {
        name: 'app.root.l1.c1.category_live',
        url: '/live-casino/:name',
        template: '<batman-game-hall1 to-top-on-state-change></batman-game-hall1>',
        params: {
            page: 'categories'
        }
    },
    // {
    // name: 'app.root.l1.c2',
    //     abstract: true,
    //     template: '<ui-view></ui-view>',
    //     params: {
    //         theme: 'jackpots',
    //         page: 'categories'
    //     }
    // },
    // {
    //     name: 'app.root.l1.c2.jackpots',
    //     url: '/jackpots',
    //     template: '<batman-game-hall4 to-top-on-state-change></batman-game-hall4>',
    //     params: {
    //         name: 'jackpots',
    //         page: 'categories',
    //     }
    // },
    // {
    //     name: 'app.root.l1.c2.category_jackpots',
    //     url: '/jackpots/:name',
    //     template: '<batman-game-hall1 to-top-on-state-change></batman-game-hall1>',
    //     params: {
    //         page: 'categories'
    //     }
    // },
    {
        name: 'app.root.l1.c',
        abstract: true,
        template: '<ui-view></ui-view>',
        params: {
            theme: 'casino',
            page: 'categories'
        }
    },
    {
        name: 'app.root.l1.c.casino',
        url: '/casino',
        template: '<linda-casino-section to-top-on-state-change></linda-casino-section>'
    },
    {
        name: 'app.root.l1.c.category',
        url: '/casino/:name',
        template: '<batman-game-hall1 to-top-on-state-change></batman-game-hall1>',
        params: {
            page: 'categories'
        }
    },
    {
        name: 'app.root.l1.c.provider',
        url: '/casino-providers/:name',
        template: '<batman-game-hall2 to-top-on-state-change></batman-game-hall2>',
        params: {
            page: 'provider'
        }
    },

    {
      name: 'app.root.l1.c.favourite',
      url: '/games/my-games',
      template: '<batman-game-box-favourites6 to-top-on-state-change></batman-game-box-favourites6>',
    },
    {
      name: 'app.root.l1.c.continue',
      url: '/games/continue-playing',
      template: '<batman-recently-played-list6 to-top-on-state-change></batman-recently-played-list6>',
    },
    {
        name: 'app.root.l1.promo',
        abstract: true,
        template: '<ui-view></ui-view>'
    },
    {
        name: 'app.root.l1.promo.list',
        url:'/promotions',
        template: '<betinia-promo-list to-top-on-state-change></betinia-promo-list>'
    },
    {
        name: 'app.root.l1.promo.page',
        url: '/promotions/:name',
        template: '<linda-promo-page to-top-on-state-change></linda-promo-page>'
    },
    {
        name: 'app.root.l1.guide',
        url: '/guide',
        template: '<linda-view-layer-three to-top-on-state-change></linda-view-layer-three>'
    },
    {
        name: 'app.root.l1.achievements',
        url: '/achievements',
        template: '<linda-achievements-page to-top-on-state-change></linda-achievements-page>'
    },
    {
        name: 'app.root.l1.payments',
        url: '/payments',
        template: '<batman-payments-page1 to-top-on-state-change></batman-payments-page1>',
        params: {
            theme: 'icons'
        }
    },
    // INFO PAGES

    {
        name: 'app.root.l1.info',
        abstract: true,
        template: '<linda-view-layer-two></linda-view-layer-two>'
    },
    {
        name: 'app.root.l1.info.sitemap',
        url: '/sitemap',
        template: '<linda-sitemap to-top-on-state-change></linda-sitemap>',
        params: {
            name: 'sitemap',
            // theme: 'violet'
        }
    },
    {
        name: 'app.root.l1.info.privacy-policy',
        url: '/privacy-policy',
        template: '<linda-info-page to-top-on-state-change></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'privacy-policy',
            theme: 'violet'
        }
    },
    {
        name: 'app.root.l1.info.about',
        url: '/about-us',
        template: '<linda-info-page to-top-on-state-change></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'about',
            theme: 'violet'
        }
    },
    {
        name: 'app.root.l1.info.contact',
        url: '/contact-us',
        template: '<linda-info-page to-top-on-state-change></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'contact-us',
            theme: 'violet'
        }
    },
    {
        name: 'app.root.l1.info.responsible-gaming',
        url: '/responsible-gaming',
        template: '<batman-info-page3 to-top-on-state-change></batman-info-page3>',
        params: {
            pageName: 'info',
            name: 'responsible-gaming',
            theme: 'violet'
        }
    },
    {
        name: 'app.root.l1.info.terms',
        url: '/rules',
        template: '<linda-info-page to-top-on-state-change></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'rules',
            theme: 'violet'
        }
    },
    {
        name: 'app.root.l1.info.cookies_policy',
        url: '/cookies-policy',
        template: '<linda-info-page to-top-on-state-change></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'cookies-policy',
            theme: 'violet'
        }
    },
    {
        name: 'app.root.l1.info.faq',
        url: '/faq',
        template: '<linda-info-page to-top-on-state-change></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'faq',
            theme: 'violet'
        }
    },
    //UI ELEMENTS
    {
        name: 'app.root.l1.ui1',
        url: '/ui1',
        template: '<batman-ui-wrapper1></batman-ui-wrapper1>'
    },
    {
        name: 'app.root.l1.ui2',
        url: '/ui2',
        template: '<batman-ui-wrapper2></batman-ui-wrapper2>'
    },
    {
        name: 'app.root.l1.ui3',
        url: '/ui3',
        template: '<batman-ui-wrapper3></batman-ui-wrapper3>'
    },
    // ---- UI-KIT LINKS
    {
        name: 'app.root.l1.ui4',
        url: '/ui4',
        template: '<batman-ui-wrapper4></batman-ui-wrapper4>'
    },
    // ---- UI-KIT ICON BUTTONS
    {
        name: 'app.root.l1.ui5',
        url: '/ui5',
        template: '<batman-ui-wrapper5></batman-ui-wrapper5>'
    },
    // ---- UI-KIT BUTTONS
    {
      name: 'app.root.l1.ui7',
      url: '/ui7',
      template: '<batman-ui-wrapper7></batman-ui-wrapper7>'
    },
    // ---- TEST LOGIN
    {
        name: 'app.root.l1.ui8',
        url: '/ui8',
        template: '<batman-ui-wrapper8></batman-ui-wrapper8>'
      },
    {
        name: 'app.root.l1.404',
        url: '/404',
        template: '<not-found></not-found>'
    },
    // PROFILE
    {
        name: 'app.root.l1.account',
        abstract: true,
        private: true,
        template: '<ui-view></ui-view>'
    },
    {
        name: 'app.root.l1.account.board',
        url: '/account/board',
        template: '<linda-profile-page to-top-on-state-change></linda-profile-page>'
    },
    {
        name: 'app.root.l1.account.info',
        url: '/account/info',
        template: '<linda-profile-settings to-top-on-state-change></linda-profile-settings>'
    },
    {
        name: 'app.root.l1.account.bonuses',
        url: '/account/bonuses',
        template: '<linda-profile-bonus-list to-top-on-state-change class="profile-bonus-list"></linda-profile-bonus-list>'
    },
    {
        name: 'app.root.l1.account.history',
        url: '/account/balance-history',
        template: '<linda-cashbox-balance-information to-top-on-state-change></linda-cashbox-balance-information>'
    },
    {
        name: 'app.root.l1.challenge',
        url: '/weekly-challenges',
        template: '<linda-view-layer-four to-top-on-state-change></linda-view-layer-four>',
        params: {
          tab: 1
        }
    },
    {
        name: 'app.root.l1.account.responsible',
        url: '/account/responsible-gaming',
        // template: '<linda-selfcontrol-wrapper to-top-on-state-change></linda-selfcontrol-wrapper>',
        template: '<denmark-profile-limits to-top-on-state-change></denmark-profile-limits>',
        params: {
            pageName: 'info',
            name: 'responsible-gaming-logged'
        }
    },
    // IDENTIFY VERIFICATION PROFILE PAGE
    {
        name: 'app.root.l1.account.verification',
        url: '/account/verification',
        template: '<dvs-page to-top-on-state-change></dvs-page>',
    },
    {
        name: 'app.root.playgrounds',
        url: '/playgrounds',
        template: `<linda-view-layer-six to-top-on-state-change />`
    },
    {
        name: 'app.root.l1.colossus',
        url: '/colossus',
        template: '<linda-view-layer-five to-top-on-state-change></linda-view-layer-five>',
        params:{
            theme: 'colossus'
        }
    },

  // <MACHINES>

  {
    name: 'app.root.claw_games',
    abstract: true,
    private: true,
    template:'<ui-view></ui-view>'
  },
  {
    name: 'app.root.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer3 />'
  },

  // </MACHINES>


/*
    {
        name: 'app.root.cash-crab',
        url: '/play/cash-crab',
        template: '<batman-view-layer3 />',
        private: true,
    },
    {
        name: 'app.root.cash_crab',
        url: '/play/cash_crab',
        template: '<batman-view-layer4 />',
        private: true,
    },
*/

    {
        name: 'app.root.game',
        abstract: true,
        template: '<linda-game-page></linda-game-page>',
        gamePage: true
    },
    {
        name: 'app.root.game.real',
        url: '/play/:name',
        template: '',
        private: true,
        params: {
            mode: 'real',
            gamePage: true
        }
    },
    {
        name: 'app.root.game.demo',
        url: '/game/:name',
        template: '<linda-demo-game-footer  game="game"></linda-demo-game-footer>',
        params: {
            mode: 'demo',
            gamePage: true
        }
    },
    {
        name: 'app.root.paynplay',
        url: '/fast-deposit',
        template: `<authentication-provider to-top-on-state-change payment-method="'devcode-mitid'"></authentication-provider>`,
        params: {
          closeButton: true,
        }
    },

    //PAYMENT CALLBACK
    {
        name: 'app.root.paymentcb',
        url: '/payment/:act?order_id&identifier',
        template: '<linda-payment-callback />',
        params: {
            type: 'deposit'
        }
    },
    {
        name: 'app.root.withdraw-paymentcb',
        url: '/withdraw-payment/:act',
        template: '<linda-payment-callback />',
        params: {
            type: 'withdrawal'
        }
    },

    //MITID CALLBACK
    {
        name: 'app.root.mitid-succes',
        url: '/operation-success?error&error_description&state&code&scope&transaction_id',
        template: '<mitid-success />',
    },
    {
        name: 'app.root.mitid-faied',
        url: '/operation-failed?error&error_description&state&code&scope&transaction_id',
        template: '<mitid-failed />',
    },

    //MISSED DATA
    {
        name: 'app.root.missedData',
        url: '/missed-data',
        private: true,
        template: '<denmark-missed-data-form />'
    },

    //application page
    {
      name: 'app.root.l1.application',
      url: '/application',
      template: '<batman-info-page4 to-top-on-state-change / >',
      params: {
        pageName: 'info',
        name: 'application'
      }
    }
];

export {router};
