import { filter } from "rxjs/operators";

const directiveName = "getFooterDataToScope";

import "./../service/domain/http.js";
import "./../service/rx/system$.js";

class FooterManagement {
  static $inject = ["http", "system$"];

  constructor(http, system$) {
    this.http = http;
    this.system$ = system$;
  }

  getDataToScope(scope) {
    this.http
      .getData("footer")
      .then(({ result }) => {
        scope.text = result.text;
        scope.hiddenText = result.hiddenText;
        scope.iconCollection = result.icons;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  link(scope) {
    this.getDataToScope(scope);

    this.subscription =
      this.system$
        .pipe(filter((message) => message.action === "statusChanged"))
        .subscribe(() => {
          this.getDataToScope(scope);
        });

    this.$onDestroy = () => {
      this.subscription.unsubscribe();
    };
  }
}

app.directive(directiveName, () => {
  return {
    restrict: "A",
    controller: FooterManagement,
    link: function(scope, element, attrs, ctrl) {
      ctrl.link(scope);
    }
  };
});
