import banner from '../../../app/service/domain/banner.js';
import localStorage from '../../../app/service/localStorage.js';
import { filter, takeUntil, take } from 'rxjs/operators';

const componentName = 'smartBannerQr';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'banner', 'localStorage', '$window', '$timeout', '$element'];

  function controller($scope, _banner, _localStorage, $window, $timeout, $element) {
    const conf = {
      category: 'banner',
      smartbannerOpenClass: 'smartbanner-open',
      defaultIos: 'smartbanner_ios',
      defaultAndroid: 'smartbanner_android',
      lsParamsKey: 'params',
      lsShownKey: 'bannerLastShown',
    };

    this.$onInit = () => {
      $scope.ios = this.ios;
      $scope.android = this.android;
      $scope.showOnly = this.showOnly;
      $scope.banner;

      displyBanner();
    };

    function getLink(bannerUrl) {
      const storeUrl = new URL(bannerUrl);
      const storeUrlbase = `${storeUrl.origin}${storeUrl.pathname}`;
      const storeUrlparams = new URLSearchParams(storeUrl.search);
      const savedParams = _localStorage.getObject(conf.lsParamsKey);
      const campaignIsValid = /^M[0-9]+_[0-9]+_/.test(savedParams.c);

      if (savedParams.mid && savedParams.c && !campaignIsValid) {
        savedParams.c = `M${savedParams.mid}_${savedParams.c}`;
        savedParams.af_channel = 'Affiliates';
      } else if (savedParams.mid && !campaignIsValid) {
        savedParams.c = `M${savedParams.mid}_`;
        savedParams.af_channel = 'Affiliates';
      }

      for (const [key, value] of Object.entries(savedParams)) {
        storeUrlparams.set(key, value);
      }

      return `${storeUrlbase}?${storeUrlparams}`;
    }

    async function getBanner(config) {
      $scope.preloader = true;

      const {
        result: [banner],
      } = await _banner.collection(config);
      $scope.preloader = false;

      return banner;
    }

    function getPlatform() {
      return /android/i.test(navigator.userAgent) ? $scope.android : $scope.ios;
    }

    async function displyBanner() {
      $scope.mobileDevice = getPlatform();
      $scope.banner = await getBanner({
        category: conf.category,
        place: $scope.mobileDevice,
      });

      if (!$scope.banner) return;

      drawQrCode();
    }

    function drawQrCode() {
      if (!$window.QRious) {
        const script = $window.document.createElement('script');
        script.onload = function () {
          $timeout(drawQrCode);
        };
        script.type = 'text/javascript';
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/qrious/4.0.2/qrious.min.js';
      } else {
        const element = $element[0].querySelector('.qr__canvas');
        const qr = new QRious({
          element: element,
          background: '#ffffff',
          backgroundAlpha: 0,
          foreground: '#ffffff',
          foregroundAlpha: 1,
          level: 'H',
          padding: 0,
          size: 200,
          value: getLink($scope.banner.url || ''),
        });
      }
    }
  }

  new Controller(componentName, controller, {
    android: '<',
    ios: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5].forEach((i) => {
    new Batman(`${componentName}${i}`, componentName);
  });
})();
