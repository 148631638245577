import config from '../../app/service/configs/config.js';
import { gsap, TimelineMax, TweenMax } from 'gsap';

(function () {

    'use strict';

    const directive = { name: 'betiniaRegistration' };

    controller.$inject = [ 'config' ];

    function controller( _config ){

        function link( scope, element, attrs ){



            scope.preloaderVideo = true;

            const   wrap = element[0].querySelector('.is-animate-wrap'),
                    line = element[0].querySelectorAll('.is-animate-line'),
                    poster = element[0].querySelector('.popup-page__poster'),
                    close = document.querySelector('.popup-page__close.is-close'),
                    skip = document.querySelector('.popup-page__close.is-skip');

            let showWrap = new TimelineMax({ paused:true})
                .to( close , 0, {opacity:1, display:"block"} , '0' )
                .to( skip , 0, {opacity:0, display:"none"} , '0' )
                .to( poster , 0.2, { opacity: '1' } , '0' )
                .to( wrap , 0.2, { opacity: '1' } , '-=0.2' );

            line.forEach((line, i) => {
                showWrap.add( TweenMax.from( line, .25, { y: '15px' , opacity:'0' }) , '-=0.15' );
            });


            let prefix = 2560;
            if( window.innerWidth < 1440 ){
                prefix = 1440;
            }else if( window.innerWidth >= 1440 && window.innerWidth < 1920 ){
                prefix = 1920;
            }

            const video = document.querySelector('#registration-video') || document.querySelector('#registration-video-2');

            if( scope.flow !== 'resume_play' && !video.ended ){
                const source1 = document.createElement( 'source' );
                const source2 = document.createElement( 'source' );
                source1.src = `${_config.cdn}/betinia/registration-video/desk/Betinia_Registration_${prefix}.av1.mp4`;
                source1.type = 'video/mp4; codecs=av01.0.05M.08';
                source2.src = `${_config.cdn}/betinia/registration-video/desk/Betinia_Registration_${prefix}.mp4`;
                source2.type = 'video/mp4; codecs=avc1.4D401E';
                video.appendChild( source1 );
                video.appendChild( source2 );
                video.addEventListener('canplay', () => scope.preloaderVideo = false );
                video.addEventListener('ended', ()=> showWrap.play() );
            }else{
                scope.preloaderVideo = false
                showWrap.play();
            }

            scope.skip = ()=> {
                scope.preloaderVideo = false;
                video.pause();
                showWrap.play();
            }
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
