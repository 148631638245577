import popup from '../../app/service/domain/popup.js';

(function () {
  'use strict';

  const directive = { name: 'openTermsPopup' };

  controller.$inject = [ 'popup', 'state'];

  function controller( _popup, _state ) {
    function link(scope, element, attrs) {
      const terms = attrs[directive.name];
      const observer = new MutationObserver(openInfoPopup);

      function openInfoPopup (mutations) {
        for(const mutation of mutations) {
          element[0].querySelector('.promo-terms-btn')?.addEventListener('click', (e) => {
            terms ? _popup.open({name: 'info', content: terms}) : _state.goto('terms');
          });
        }
      }
      scope.$on( '$destroy', () => {
        observer.disconnect();
      });
      observer.observe(element[0], {
        childList: true,
        attributes: true,
        subtree: true
      });
    }

    return {
      restrict: 'A',
      link
    };

  }


  app.directive( directive.name, controller );
})();
