import game from '../../app/service/domain/game.js';

(function () {
    controller.$inject = [
        '$scope',
        'game'
    ];

    function controller(
        $scope,
        _game,
    ) {
        $scope.collection = [];
        $scope.preloader = false;
        $scope.category = null;

        $scope.total_count = 0;

        const config = {};

        let skeletonCollection = [];
        let startIndex = 0;

        function getCollection(options) {
            if (!$scope.preloader) {
                $scope.preloader = true;
                $scope.collection = $scope.collection.concat(skeletonCollection);
                _game
                    .collection(options)
                    .then(({ result }) => {
                        $scope.collection.splice(
                            startIndex,
                            skeletonCollection.length,
                            ...result
                        );

                        const rows = result.length / $scope.columns;

                        const items = result.length % $scope.columns;
                        const stubs = items ? $scope.columns - items : 0;

                        if (!items || stubs > $scope.stubs) {
                            $scope.collection.length = $scope.columns * rows;
                        } else if (stubs <= $scope.stubs) {
                            $scope.collection.length = $scope.columns * rows + 1;
                        }

                        $scope.preloader = false;
                    })
                    .catch(() => {
                        $scope.collection = [];
                    })
                    .finally(() => {
                        $scope.preloader = false;
                    });
            }
        }

        this.$onChanges = function () {
            $scope.collection = [];

            if (this.skeleton) {
                skeletonCollection = [];
                skeletonCollection.length = this.count || 1;
                skeletonCollection.fill({}, 0, this.count || 1);
            }

            $scope.category = this.category;
            $scope.columns = this.columns || 6;
            $scope.stubs = this.stubs || 2;

            config.category = this.category;
            config.count = this.count;

            getCollection(config);
        };
    }

    const bindings = {
        category: '<',
        count: '<',
        skeleton: '<',
        columns: '<',
        stubs: '<',
    };

    new Controller('betiniaGameBox', controller, bindings);
})();

(function () {
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].forEach((i) => {
        new Batman(`betiniaGameBox${i}`, 'betiniaGameBox');
    });
})();
