import '../../service/domain/http.js';
import '../../service/domain/user.js';
(function () {
  'use strict';

  controller.$inject = ['$q', 'http', 'user'];

  function controller($q, _http, _user) {
    const result = {
      deposit: {
        get() {
          return _user.limits.deposit;
        },
        set(data) {
          const defer = $q.defer(),
            alias = 'mgaDepositLimit',
            config = {
              meta: { alias },
            };

          _http.put(alias, data, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
        delete() {
          const defer = $q.defer(),
            alias = 'mgaDepositLimit',
            config = {
              meta: { alias },
            };

          _http.delete(alias, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      session: {
        get() {
          return _user.limits.session;
        },
        set(data) {
          const defer = $q.defer(),
            alias = 'mgaSessionLimit',
            config = {
              meta: { alias },
            };

          _http.put(alias, data, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
        delete() {
          const defer = $q.defer(),
            alias = 'mgaSessionLimit',
            config = {
              meta: { alias },
            };

          _http.delete(alias, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
        continue(data) {
          const defer = $q.defer(),
            alias = 'mgaSessionLimitContinue',
            config = {
              meta: { alias },
            };

          _http.post(alias, data, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      self_exclusion: {
        get() {
          return _user.limits.self_exclusion;
        },
        set(data) {
          const defer = $q.defer(),
            alias = 'mgaSelfExclusion',
            config = {
              meta: { alias },
            };

          _http.post(alias, data, config).then(
            (answer) => {
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
        continue(data) {
          const defer = $q.defer(),
            alias = 'mgaSelfExclusion',
            config = {
              meta: { alias },
            };

          _http.put(alias, data, config).then(
            (answer) => {
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      closure: {
        get() {
          const defer = $q.defer(),
            alias = 'mgaClosure',
            config = {
              meta: { alias },
            };

          _http.get(alias, config).then(
            (answer) => {
              defer.resolve(answer.data.result);
            },
            (answer) => {
              defer.resolve(answer.data);
            }
          );

          return defer.promise;
        },
        set(data) {
          const defer = $q.defer(),
            alias = 'mgaClosure',
            config = {
              meta: { alias },
            };

          _http.post(alias, data, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      losses: {
        get() {
          return _user.limits.losses;
        },
        set(data) {
          const defer = $q.defer(),
            alias = 'mgaLossesLimit',
            config = {
              meta: { alias },
            };

          _http.put(alias, data, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
        delete() {
          const defer = $q.defer(),
            alias = 'mgaLossesLimit',
            config = {
              meta: { alias },
            };

          _http.delete(alias, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      wager: {
        get() {
          return _user.limits.wager;
        },
        set(data) {
          const defer = $q.defer(),
            alias = 'mgaWagerLimit',
            config = {
              meta: { alias },
            };

          _http.put(alias, data, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
        delete() {
          const defer = $q.defer(),
            alias = 'mgaWagerLimit',
            config = {
              meta: { alias },
            };

          _http.delete(alias, config).then(
            (answer) => {
              _user.profile = answer.data.result;
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },
    };

    return result;
  }

  app.factory('mgaLimit', controller);
})();
