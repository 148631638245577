import '../../service/mga/mga-limit.js';
import '../../service/domain/modal.js';
import '../../service/domain/content-buffer.js';
import '../../service/domain/state.js';
import '../../service/domain/account.js';
(function () {
  'use strict';

  const component = { name: 'mgaExceededSessionLimitModal' };

  controller.$inject = ['$scope', 'mgaLimit', 'modal', 'contentBuffer', 'state', 'account'];

  function controller($scope, _mgaLimit, _modal, _contentBuffer, _state, _account) {
    $scope.preloader = false;
    $scope.data = _contentBuffer.read('modal').data;

    const continueSession = () => {
      $scope.preloader = true;
      return _mgaLimit.session
        .continue({ token: $scope.data.token })
        .then(() => {
          _modal.answer = true;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };
    $scope.continueSession = continueSession;

    $scope.logout = () => {
      const isFrontLogout = $scope.data.actionType === 'logout';
      _account
        .logout(isFrontLogout)
        .catch(() => {})
        .finally(() => (_modal.answer = true));
    };

    $scope.gotoBalanceHistory = () => {
      continueSession().then(() => {
        _modal.answer = true;
        _state.goto('balanceHistory');
      });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
