(function () {
  const directive = { name: 'getPromoBanner' };

  function controller() {
    function link(scope, element, attrs) {
      const {name, target} = scope.$eval(attrs[directive.name]);

      scope.$watchCollection('collection', (collection) => {
        if (collection.length && name) {
          scope[name] = collection.find(p => p.name === target);
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
