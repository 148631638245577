import '../../../service/helper.js';
import '../../../service/domain/user.js';
import '../../../service/dk/dk_limits.js';
(function () {
  'use strict';

  const component = { name: 'denmarkProfileSessionLimit' };

  const group = 'session_limit';
  const unit = '1';

  controller.$inject = ['$scope', 'helper', 'user', 'dkLimits'];

  function controller($scope, _helper, _user, _dkLimits) {
    $scope.preloader = false;

    $scope.formField = {
      minutes: {
        min: 1,
        max: 1000,
      },
    };

    $scope.formData = {
      session_limit: _user.profile[group].value ? parseInt(_user.profile[group].value.slice(1)) : 0,
    };

    $scope.limit = { ...$scope.formData, ..._user.profile[group] };
    $scope.limit.next_amount = _user.profile[group].next_value ? parseInt(_user.profile[group].next_value.slice(1)) : 0;

    function setLimit(a) {
      $scope.formData.session_limit = a.data.result[group].value ? parseInt(a.data.result[group].value.slice(1)) : 0;
      $scope.limit = { ...$scope.formData, ..._user.profile[group] };
      $scope.limit.next_amount = a.data.result[group].next_value
        ? parseInt(a.data.result[group].next_value.slice(1))
        : 0;
    }

    $scope.submit = _helper.form.validator((form) => {
      const o = {
        unit,
        session_limit: parseInt($scope.formData.session_limit),
      };

      $scope.preloader = true;

      _dkLimits.sessionLimit
        .set(o)
        .then((a) => {
          setLimit(a);
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });

    $scope.cancel = () => {
      $scope.preloader = true;

      _dkLimits.sessionLimit
        .cancel()
        .then((a) => {
          setLimit(a);
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
