(function () {
    const directive = { name: 'collectionToParent' };

    function controller() {
        function link(scope, element, attrs) {
            const varName = attrs[directive.name];
            console.log('varName ::: ', varName); 

            scope.$watchCollection('collection', (collection) => {
                if (collection.length) {
                    scope.$parent.$parent[varName] = collection;
                    console.log('scope.$parent[varName] ::: ', scope.$parent[varName]); 
                    console.log('scope ::: ', scope); 
                }
            });
        }


        return {
            restrict: 'A',
            link,
        };
    }

    app.directive(directive.name, controller);
})();