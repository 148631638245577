import '../../../service/domain/payment.js';
import '../../../service/domain/modal.js';
import '../../../service/domain/user.js';
import '../../../service/helper.js';
import '../../../service/domain/popup.js';
import '../../../service/rx/ws$.js';
import '../../../service/rx/notification$.js';
import '../../../service/configs/ws-events.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaCashboxWithdrawIframeForm' };

  controller.$inject = [
    '$rootScope',
    '$scope',
    'payment',
    'modal',
    'user',
    'helper',
    'popup',
    'ws$',
    'notification$',
    'wsEvents',
    '$translate',
  ];

  function controller(
    $rootScope,
    $scope,
    _payment,
    _modal,
    _user,
    _helper,
    _popup,
    _ws$,
    _notification$,
    _wsEvents,
    $translate
  ) {
    $scope.preloader = false;
    $scope.system = {};

    $scope.transactionStatus = '';
    $scope.withdrawStep = 1;
    $scope.formData = {
      amount: 0,
      details: [],
    };

    let subscription;

    this.$onChanges = () => {
      if (typeof this.system === 'object') {
        $scope.system = this.system;
        $scope.formData.paymethod = this.system.rules.system;
        $scope.formData.paysystem_id = this.system.id;
      }
    };

    function resolveOnPostMessage(message) {
      if (message.data === 'transaction-success') {
        _popup.close();
        openSuccessNotification();
      }
      if (message.data === 'transaction-fail') {
        $scope.withdrawStep = 3;
      }
    }

    function resolveOnSocket(message) {
      $scope.transactionStatus = message.data.status;
      $scope.iframeLink = '';
      if ($scope.transactionStatus === 'success') {
        _popup.close();
        openSuccessNotification();
        return;
      }
      $scope.withdrawStep = 3;
    }

    function listenTransactionEvents() {
      window.addEventListener('message', resolveOnPostMessage);
      _ws$.pipe(filter(({ event }) => event === _wsEvents.cashierStatus)).subscribe((message) => resolveOnSocket(message));
    }

    function openSuccessNotification() {
      _notification$.next({
        type: 'success',
        code: 0,
        text: $translate.instant('withdrawal.transaction_success'),
      });
    }

    function withdraw() {
      $scope.preloader = true;
      $scope.iframeLink = `/preloader.html?login=${_user.profile.login}`;
      _payment
        .withdraw($scope.formData)
        .then(({ result }) => {
          $scope.preloader = false;
          if (result.fast_withdraw === true) {
            _popup.close();
            openSuccessNotification();
            return;
          }
          if (result.url.length) {
            $scope.withdrawStep = 2;
            $scope.iframeLink = result.url;
            listenTransactionEvents();
          }
        })
        .catch((a) => {
          $scope.withdrawStep = 3;
          $scope.transactionStatus = 'error';
        })
        .finally((a) => {
          $scope.preloader = false;
        });

      $rootScope.$emit('withdraw-new');
    }

    $scope.submit = _helper.form.validator((form) => {
      if (_user.profile.active_bonus > 0) {
        _modal.open('cashbox-withdraw').then((a) => {
          withdraw();
        });
      } else {
        withdraw();
      }
    });

    this.$onDestroy = () => {
      subscription?.unsubscribe();
      window.removeEventListener('message', resolveOnPostMessage);
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      system: '<',
    },
  });
})();
