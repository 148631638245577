import '../../service/analytics/analytics.js';
import '../../service/configs/analytic-events.js';
(function () {
  'use strict';

  const component = { name: 'lindaSidemenu' };

  controller.$inject = ['$scope', 'analytics', 'analyticsEvents'];

  function controller($scope, analytics, _analyticsEvents) {
    $scope.sendOpenReg = (area) => {
      analytics.send(_analyticsEvents.createAccountButton, `side_menu_${area}`);
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
