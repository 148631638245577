(function () {
  'use strict';

  controller.$inject = ['$scope'];

  function controller($scope) {
    $scope.preloader = false;
    $scope.collection = [];

    const mockList = [
      { id: '', img: [], sportids: '0', alias: 'football', title: 'Football' },
      { id: '', img: [], sportids: '0', alias: 'tennis', title: 'Tennis' },
      { id: '', img: [], sportids: '0', alias: 'basketball', title: 'Basketball' },
      { id: '', img: [], sportids: '0', alias: 'ice_hockey', title: 'Ice hockey' },
      { id: '', img: [], sportids: '0', alias: 'table_tennis', title: 'Table Tennis' },
      { id: '', img: [], sportids: '0', alias: 'baseball', title: 'Baseball' },
      { id: '', img: [], sportids: '0', alias: 'volleyball', title: 'Volleybal' },
      { id: '', img: [], sportids: '0', alias: 'handball', title: 'Handball' },
      { id: '', img: [], sportids: '0', alias: 'badminton', title: 'Badminton' },
      { id: '', img: [], sportids: '0', alias: 'cricket', title: 'Cricket' },
      { id: '', img: [], sportids: '0', alias: 'american_football', title: 'American Football' },
      { id: '', img: [], sportids: '0', alias: 'esport', title: 'E-Sport' },
      { id: '', img: [], sportids: '0', alias: 'snooker', title: 'Snooker' },
      { id: '', img: [], sportids: '0', alias: 'mma', title: 'MMA' },
      { id: '', img: [], sportids: '0', alias: 'darts', title: 'Darts' },
      { id: '', img: [], sportids: '0', alias: 'bowl', title: 'Bowls' },
    ];

    $scope.collection = mockList;
  }

  new Controller('sportCategoryList', controller, {});
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportCategoryList${i}`, 'sportCategoryList');
  });
})();
