import state from '../../../app/service/domain/state.js';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'state', '$interval'];

  const component = { name: 'mitidFailed' };

  function controller(scope, state, interval) {
    let i = 10;

    scope.timer = `00:${i}`;
    scope.action = 'preloader';

    makeTimer();

    scope.close = function () {
      state.goto('home');
    };

    function makeTimer() {
      const timer = interval(() => {

        if (i >= 0) {
          scope.timer = i >= 10 ? `00:${i}` : `00:0${i}`;
          i--;
        } else {
          interval.cancel(timer);
          scope.close();
        }
      }, 1000);
    }
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {}
  });
})();
