import '../../../service/helper.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/dk/dk_limits.js';
(function () {
  'use strict';

  const component = { name: 'denmarkSelfExclusionPopup' };

  controller.$inject = ['$scope', 'helper', 'state', 'popup', 'contentBuffer', 'dkLimits'];

  function controller($scope, _helper, _state, _popup, _contentBuffer, _dkLimits) {
    $scope.content = _contentBuffer.read('popup');
    $scope.preloader = false;

    $scope.formData = {
      password: '',
    };

    $scope.submit = _helper.form.validator((form) => {
      const o = {
        action: $scope.content.action,
        password: $scope.formData.password,
      };

      if ($scope.content.frozen_to) {
        o.frozen_to = $scope.content.frozen_to;
        o.unit = $scope.content.unit;
      }

      $scope.preloader = true;

      _dkLimits.exclude
        .set(o)
        .then((a) => {
          _popup.close();
          _state.goto('home');
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
