import system from '../../app/service/domain/system.js';
(function () {
  'use strict';

  const directive = { name: 'betiniaGetCountriesToScope' };

  controller.$inject = ['system'];

  function controller(_system) {
    function link(scope, element, attrs) {

      Promise.all([_system.info(), _system.countries()]).then((result) => {


        let listOfAvailableCountries = result[1].result;
        let currentCountryCode = result[0]?.result.country.code;


        scope.country = listOfAvailableCountries.find(c => c.code === currentCountryCode)
          || listOfAvailableCountries.find(c => c.code === 'DK')

        scope.formData.phone = scope.country.prefix;
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
