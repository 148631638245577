import {gsap} from 'gsap';
import ScrollTrigger from '../../app/libs/gsap/ScrollTrigger.min.js';

(function () {

    'use strict';

    const directive = { name: 'betiniaToggleLoginBtnColor' };

    function controller(){

        function link( scope, element, attrs ){

            gsap.registerPlugin(ScrollTrigger);

            let header = document.querySelector('.header');

            const headerBtns = header.querySelectorAll('.header__btn');

            headerBtns.forEach(btn => {
                btn.classList.add('btn-line');
                btn.classList.remove('btn-orange');
            });


            scope.scr = ScrollTrigger.create({
                trigger: element,
                start: "top top+=62px",
                end: "bottom top+=62px",
                onLeave: () => {
                    headerBtns.forEach(btn => {
                        btn.classList.add('btn-orange');
                        btn.classList.remove('btn-line');
                    });
                },
                onLeaveBack:  () => {
                    headerBtns.forEach(btn => {
                        btn.classList.add('btn-line');
                        btn.classList.remove('btn-orange');
                    });
                }
            });
            scope.$on('$destroy', () => {
                scope.scr.kill(false);
                headerBtns.forEach(btn => {
                    btn.classList.remove('btn-line');
                    btn.classList.add('btn-orange');
                });
            });

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
