import '../../service/mga/mga-limit.js';
import '../../service/domain/modal.js';
import '../../service/domain/popup.js';
import '../../service/helper.js';
import '../../service/domain/user.js';
import '../../service/rx/user$.js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'mgaSetSessionLimitPopup' };

  controller.$inject = ['$scope', 'mgaLimit', 'modal', 'popup', 'helper', 'user', 'user$', '$timeout'];

  function controller($scope, _mgaLimit, _modal, _popup, _helper, _user, _user$, $timeout) {
    const vm = this;
    const destroy$ = new Subject();

    const aliasMinute = 'minute';
    const aliasHour = 'hour';

    const popupCurrent = '/mga-set-session-limit-popup.html';

    $scope.sessionShowFullForm = false;

    $scope.$watch('sessionLength', (newValue) => {
      $scope.sessionShowFullForm = newValue === 'other';
    });

    const currentLimit = {
      period: _user.profile.limits && _user.profile.limits.session ? _user.profile.limits.session.period : '',
      amount: _user.profile.limits && _user.profile.limits.session ? _user.profile.limits.session.amount : 0,
    };

    $scope.preloader = false;
    $scope.formData = { ...currentLimit };
    $scope.formField = {
      amountMin: 1,
      amountMax: 1440,
    };

    $scope.valuesOfSessionLength = [
      {
        alias: '30-minute',
        period: aliasMinute,
        amount: 30,
      },
      {
        alias: '1-hour',
        period: aliasHour,
        amount: 1,
      },
      {
        alias: '2-hour',
        period: aliasHour,
        amount: 2,
      },
      {
        alias: '5-hour',
        period: aliasHour,
        amount: 5,
      },
      {
        alias: '12-hour',
        period: aliasHour,
        amount: 12,
      },
      {
        alias: 'other',
        period: aliasMinute,
        amount: 0,
      },
    ];

    vm.$onInit = () => {
      $timeout(() => {
        $scope.sessionLength = getSessionLengthValueAccordingToCurrentLimit();
      });
      $scope.$watch('formData.period', updateValidation);

      _user$.pipe(takeUntil(destroy$)).subscribe(updateVMStateWhenLimitUpdated);
    };

    $scope.showCancel = () => (_user.profile.limits && _user.profile.limits.session ? true : false);

    $scope.showFullForm = () => $scope.sessionLength === 'other';

    $scope.isSubmitButtonDisabled = () => {
      const { amount: currentAmount, period: currentPeriod } = currentLimit;

      return !$scope.formData.amount ||
        (_user.profile.limits &&
          _user.profile.limits.session &&
          currentAmount === $scope.formData.amount &&
          currentPeriod === $scope.formData.period)
        ? true
        : false;
    };

    $scope.cancel = () => {
      $scope.preloader = true;

      _mgaLimit.session
        .delete()
        .then(() => popupClose())
        .finally(() => ($scope.preloader = false));
    };

    $scope.submit = _helper.form.validator(() => {
      if (!isNeedConfirmation()) {
        setSessionLimit();
        updateVMStateWhenLimitUpdated();
        return;
      }

      _modal.open('mga-confirm-session-limit').then(() => {
        setSessionLimit();
        updateVMStateWhenLimitUpdated();
      });
    });

    $scope.selectSessionLength = (alias) => {
      if (alias) {
        const { period, amount } = $scope.valuesOfSessionLength.find((item) => item.alias === alias);
        $scope.sessionLength = alias;
        $scope.formData.period = period || aliasMinute;
        $scope.formData.amount = amount || 0;
      }
    };

    const popupClose = () => {
      if (_popup.current === popupCurrent) {
        _popup.close();
      }
    };

    const getSessionLengthValueAccordingToCurrentLimit = () => {
      if (currentLimit.period === '') {
        return '';
      }

      for (let i = 0; i < $scope.valuesOfSessionLength; i++) {
        const { period: presetPeriod, amount: presetAmount, alias } = $scope.valuesOfSessionLength[i];
        const { period: profilePeriod, amount: profileAmount } = currentLimit;

        if (presetPeriod == profilePeriod && profileAmount === presetAmount) {
          return alias;
        }
      }

      return 'other';
    };

    const setSessionLimit = () => {
      $scope.preloader = true;

      _mgaLimit.session
        .set($scope.formData)
        .then(() => popupClose())
        .finally(() => ($scope.preloader = false));
    };

    const isNeedConfirmation = () => {
      if (!_user.profile.limits || !_user.profile.limits.session) {
        return true;
      }
      let amountInMinutes = _user.profile.limits.session.amount;
      let futureAmountInMinutes = $scope.formData.amount;

      switch (_user.profile.limits.session.period) {
        case aliasMinute:
          break;
        case aliasHour:
          amountInMinutes *= 60;
          break;
      }
      switch ($scope.formData.period) {
        case aliasMinute:
          break;
        case aliasHour:
          futureAmountInMinutes *= 60;
          break;
      }
      return futureAmountInMinutes > amountInMinutes;
    };

    const updateVMStateWhenLimitUpdated = () => {
      currentLimit.period =
        _user.profile.limits && _user.profile.limits.session ? _user.profile.limits.session.period : '';
      currentLimit.amount =
        _user.profile.limits && _user.profile.limits.session ? _user.profile.limits.session.amount : 0;

      $scope.formData = { ...currentLimit };
      $scope.sessionLength = getSessionLengthValueAccordingToCurrentLimit();
    };

    const updateValidation = (alias) => {
      if (alias) {
        $scope.formField.amountMax = alias === aliasMinute ? 1440 : 24;
      }
    };

    $scope.$on('$destroy', () => {
      destroy$.next();
      destroy$.unsubscribe();
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
