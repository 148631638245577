import '../../../service/zignsec/zignsec.paynplay.js';
import '../../../service/helper.js';
import '../../../service/rx/trustly$.js';
import '../../../service/configs/zignsec.js';

import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaZignsecPaynplayPage' };

  controller.$inject = [
    '$scope',
    'zignsec.paynplay',
    'helper',
    'config',
    'user',
    'state',
    'trustly$',
    '$timeout',
    'zignsec.config',
    '$window',
    'system$',
  ];

  function controller(
    $scope,
    _zignsec_paynplay,
    _helper,
    _config,
    _user,
    _state,
    _trustly$,
    $timeout,
    _zignsec_config,
    $window,
    _system$
  ) {
    const redirectToHomepage = () => _state.goto('home');
    let loginSubscription;

    if (!_zignsec_config?.enabled || _user.status) {
      redirectToHomepage();
      return;
    }

    loginSubscription = _system$.pipe(filter(({ action }) => action === 'login')).subscribe(redirectToHomepage);

    $scope.amount = _zignsec_paynplay.data.amount;
    $scope.personId = _zignsec_paynplay.data.personId;
    $scope.step = _zignsec_paynplay.data.step;
    $scope.flow = _zignsec_paynplay.data.flow;
    $scope.paysystem = _zignsec_config;

    let subscription;

    $scope.submit = _helper.form.validator((form) => {
      if ($scope.amount > 0) {
        _zignsec_paynplay.data.amount = $scope.amount;
        _zignsec_paynplay.data.personId = $scope.personId;
        _zignsec_paynplay.data.step = 1;
      }
    });

    function handler(event) {
      if (event.origin !== 'https://trustly.com') {
        return;
      }

      const data = JSON.parse(event.data);

      if (data.method === 'OPEN_APP') {
        $window.location.assign(data.appURL);
      }
    }

    this.$onInit = () => {
      subscription = _trustly$
        .pipe(filter((item) => item.action === 'step'))
        .subscribe((item) => {
          $timeout(() => {
            $scope.step = item.data;
          });
        });
      $window.addEventListener('message', handler, false);
    };

    this.$onDestroy = () => {
      _zignsec_paynplay.data.reset();
      subscription.unsubscribe();
      loginSubscription?.unsubscribe();
      $window.removeEventListener('message', handler, false);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
