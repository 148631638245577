import remembered from '../../app/service/domain/remembered.js';
(function () {

  'use strict';

  const directive = { name: 'betiniaHasRememberedLogin' };

  controller.$inject = [ 'remembered' ];
  function controller( _remembered ){

    function link( scope ){
      scope.hasRememberedLogin = _remembered.login;
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );

})();
