(function () {
    'use strict';

    const directive = { name: 'betiniaDefaultTimer' };

    function controller() {
        function link( scope ) {
            const millisecondsInDay = 24*60*60*1000;

            scope.defaultTimer = millisecondsInDay - new Date() % millisecondsInDay;
        }

        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();