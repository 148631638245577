(function() {

    //- TODO need refactor. current directive doesn't use

    'use strict';

    const directive = { name: 'betiniaBackBtn' };

    controller.$inject = [ '$state' ];

    function controller( $state ){

        const link = ( scope, element, attrs ) => {

          element.bind('click', function(e) {
            history.back();
          });

        }

        return {
            link
        };
    }

    app.directive( directive.name, controller );

})();
