import '../../service/domain/user.js';
import '../../service/domain/dynamic-form.js';
import '../../service/helper.js';
import '../../service/domain/system.js';
import '../../service/domain/account.js';
import '../../service/domain/state.js';
import '../../service/configs/config.js';
import '../../service/domain/modal.js';
import '../../service/trustly/paynplay.js';

(function () {
  'use strict';

  const component = { name: 'denmarkMissedDataForm' };

  controller.$inject = [
    '$scope',
    'user',
    'dynamicForm',
    'helper',
    'system',
    'account',
    'state',
    'config',
    'modal',
    'paynplay',
  ];

  function controller($scope, _user, _dynamicForm, _helper, _system, _account, _state, _config, _modal, _paynplay) {
    const missedFields = _user.profile.requiredFields || [];
    let stateChangeAccess = false;

    $scope.currentStep = 2;
    $scope.amountOfSteps = 4;

    $scope.preloader = false;
    $scope.birth_countries = [];
    $scope.nationalities = [];
    $scope.profileFormData = undefined;
    $scope.formData = _dynamicForm.getFormData();
    $scope.missedFields = convertMissedFields(missedFields);
    $scope.defaultFields = {
      gender: 'M',
      gdpr_marketing: false,
    };
    $scope.nextPage = () => {
      $scope.currentStep++;
    };
    $scope.previousPage = () => {
      $scope.currentStep--;
    };

    const preparePhoneField = () => {
      $scope.preloader = true;
      _system
        .countries()
        .then((a) => {
          const country = a.result.filter((item) => item.code === a.default)[0] || {};

          if (!$scope.profileFormData.phone && typeof country.prefix === 'string') {
            $scope.profileFormData.phone = `+${parseInt(country.prefix.replace(/\-/g, ''), 10)}`;
          }
        })
        .catch((e) => console.error(e))
        .finally(() => ($scope.preloader = false));
    };

    const prepareDefaultFields = (requiredFields) => {
      $scope.profileFormData = {};
      requiredFields.forEach((item) => {
        if ($scope.defaultFields.hasOwnProperty(item)) {
          $scope.profileFormData[item] = $scope.defaultFields[item];
        }
      });
    };
    if (_user.profile.profile.is_profile_complete === false) {
      _paynplay.getFulfillmentInfo().then((answer) => {
        $scope.personMissedFields = convertMissedFields(answer.requiredFields);
        if ($scope.personMissedFields.phone) {
          preparePhoneField();
        }
        prepareDefaultFields(answer.requiredFields);
      });
    }

    const checkFormDataOnTriggerCodes = () => {
      if ($scope.profileFormData?.enable_trigger_codes !== true) {
        delete $scope.profileFormData.trigger_codes;
      }
      delete $scope.profileFormData?.enable_trigger_codes;
    };

    const authPromises = () => {
      const authPromises = [];
      if (_user.profile.profile?.is_profile_complete === false) {
        checkFormDataOnTriggerCodes();
        authPromises.push(_paynplay.sendFulfillmentInfo($scope.profileFormData));
      }
      if (_user.profile.requiredFields?.length > 0) {
        authPromises.push(_account.missedFields($scope.formData));
      }
      return authPromises;
    };
    const isRequiredDataFilled = () => {
      const userIsComplete = _user.profile.profile?.is_profile_complete;
      if (!_user.profile.profile.hasOwnProperty('is_profile_complete')) {
        $scope.currentStep = 4;
        return !_user.profile.requiredFields.length;
      }
      defineCurrentStep(userIsComplete);
      return userIsComplete && !_user.profile.requiredFields.length;
    };

    const defineCurrentStep = (userIsComplete) => {
      if (userIsComplete) {
        $scope.currentStep = 4;
      }
      if (!missedFields.length) {
        $scope.amountOfSteps = 3;
      }
    };

    // for test uncomment below
    // $scope.missedFields = {
    //   profile_pep: true,
    //   accept_terms_and_conditions: true,
    // }

    getNationalities();

    $scope.cancelDepositLimitChange = (form) => {
      $scope.formData.update_multi_limits = [
        {
          type: 'day',
          amount: _user.profile.limits.deposit.day.amount,
          group: 'deposit',
        },
        {
          type: 'week',
          amount: _user.profile.limits.deposit.week.amount,
          group: 'deposit',
        },
        {
          type: 'month',
          amount: _user.profile.limits.deposit.month.amount,
          group: 'deposit',
        },
      ];

      $scope.submit(form);
    };

    $scope.submit = _helper.form.validator(async (form) => {
      $scope.preloader = true;

      try {
        if (_user.profile.profile?.is_profile_complete === false) {
          checkFormDataOnTriggerCodes();
          await _paynplay.sendFulfillmentInfo($scope.profileFormData);
        }
        if (_user.profile.requiredFields?.length > 0) await _account.missedFields($scope.formData);

        goHome();
        if (_config.jurisdiction === 'sweden') {
          const swedenLimits = [
            'limits_deposit_day_sweden',
            'limits_deposit_week_sweden',
            'limits_deposit_month_sweden',
          ];
          if (
            swedenLimits.some((el) => missedFields.includes(el)) &&
              !missedFields.includes('accept_deposit_limit_change')
          ) {
            _state.goto('deposit');
          }
        }
        setTimeout(() => {
          form.$setSuccessful();
        }, 0);
      } catch (error) {
        console.log('Error ::: ', error);
      } finally {
        $scope.preloader = false;
      }

      // Promise.all(authPromises()).then((answer) => {
      //   goHome();
      //   if (_config.jurisdiction === 'sweden') {
      //     const swedenLimits = [
      //       'limits_deposit_day_sweden',
      //       'limits_deposit_week_sweden',
      //       'limits_deposit_month_sweden',
      //     ];
      //     if (
      //       swedenLimits.some((el) => missedFields.includes(el)) &&
      //       !missedFields.includes('accept_deposit_limit_change')
      //     ) {
      //       _state.goto('deposit');
      //     }
      //   }
      //
      //   setTimeout(() => {
      //     form.$setSuccessful();
      //   }, 0);
      // }).catch(() => {
      // }).finally(() => ($scope.preloader = false));
    });

    this.$onInit = () => {
      if (isRequiredDataFilled()) {
        // for test comment below
        goHome();
      }
    };

    this.$onChanges = () => {
      const steps = this.stepsCount || 4;

      $scope.amountOfSteps = steps < 4 ? 4 : steps;
    };

    $scope.$on('$stateChangeStart', (event) => {
      if (!stateChangeAccess && _user.status) {
        event.preventDefault();
      }
    });

    $scope.cancel = () => {
      _modal
        .open('missed-data-cancellation')
        .then(() => goHome())
        .catch(() => {});
    };

    $scope.logout = () => {
      _account
        .logout()
        .then(() => goHome())
        .catch(() => {});
    };

    function getNationalities() {
      if ($scope.missedFields.profile_nationality || $scope.missedFields.profile_birth_place) {
        $scope.preloader = true;

        _system
          .nationality()
          .then(({ result }) => {
            $scope.nationalities = [];
            $scope.birth_countries = [];

            result.forEach(({ nationality, alpha_2_code, en_short_name }) => {
              $scope.nationalities.push({
                name: nationality,
                code: alpha_2_code,
              });
              $scope.birth_countries.push({
                name: en_short_name,
                code: alpha_2_code,
              });
            });
          })
          .catch(() => {})
          .finally(() => ($scope.preloader = false));
      }
    }

    function convertMissedFields(missedFields) {
      return missedFields.reduce((fieldsObj, field) => {
        fieldsObj[field] = true;
        return fieldsObj;
      }, {});
    }

    function goHome() {
      stateChangeAccess = true;
      _state.goto('home');
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      stepsCount: '<',
    },
  });
})();
