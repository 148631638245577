import '../../../service/dk/dk_account.js';
import '../../../service/domain/popup.js';

(function () {
  'use strict';

  const component = { name: 'denmarkLoginForm' };

  controller.$inject = ['$scope', 'dk_account', 'popup', '$state'];

  function controller($scope, _dk_account, _popup, $state) {
    $scope.preloader = false;
    $scope.credentials = {};

    let successRedirectState = null;

    this.$onInit = function () {
      successRedirectState = this.successRedirectState;
    };

    $scope.submit = function (form) {
      if (form.$valid) {
        $scope.preloader = true;
        _dk_account
          .login($scope.credentials)
          .then((answer) => {
            _popup.close();

            if (typeof _popup.cb === 'function') {
              _popup.cb();
              _popup.cb = null;
            }

            if (successRedirectState && $state.get(successRedirectState)) {
              $state.go(successRedirectState);
            }

            $scope.preloader = false;
          })
          .catch((e) => {})
          .finally(() => {
            $scope.preloader = false;
          });
      } else {
        angular.forEach(form.$error, function (type) {
          angular.forEach(type, function (field) {
            field.$setTouched();
          });
        });
      }
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
