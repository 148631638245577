import '../../../service/domain/provider.js'
(function () {
  controller.$inject = ['$scope', 'provider'];

  function controller($scope, _provider) {
    $scope.collection = [];
    $scope.preloader = false;

    this.$onChanges = () => {
      $scope.preloader = true;

      _provider
        .collection()
        .then((answer) => {
          $scope.collection = answer.result;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('providerList', controller);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanProviderList${i}`, 'providerList');
  });
})();
