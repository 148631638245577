import '../../../service/domain/dashboard.js';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'dashboard'];

  function controller($scope, _dashboard) {
    $scope.collection = [];
    $scope.preloader = false;

    const options = {
      page: 1,
    };

    const getCollection = (o) => {
      _dashboard
        .collection(o)
        .then((a) => {
          $scope.collection = a.result;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    this.$onChanges = () => {
      options.count = this.count || 25;
      if (this.category) options.category = this.category;

      getCollection(options);
    };
  }

  new Controller('dashboardList', controller, {
    category: '<',
    count: '<',
  });
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanDashboardList${i}`, 'dashboardList');
  });
})();
