import { Observable } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = ['$interval'];

  function controller($interval) {
    const subscriptions = new Map();

    const generateStream = (id, amount, increment) => {
      const coef = Math.random() * 2 + 1;
      const updateInterval = Math.floor(coef * 1000);

      return new Observable((observer) => {
        const intervalId = $interval(() => {
          observer.next((amount = amount + increment * coef));
        }, updateInterval);

        return () => {
          $interval.cancel(intervalId);
          subscriptions.delete(id);
        };
      });
    };

    return {
      getStream(id, amount, increment) {
        if (!subscriptions.has(id)) {
          subscriptions.set(id, generateStream(id, amount, increment));
        }

        return subscriptions.get(id);
      },
    };
  }

  app.factory('gameJackpot', controller);
})();
