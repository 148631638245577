import gsap from 'gsap';
import InertiaPlugin from '../../app/libs/gsap/InertiaPlugin.min.js';

(function () {
    'use strict';

    const directive = { name: 'playgroundMap' };

    controller.$inject = [] ;

    function controller(){

        function link( scope, element ){
            gsap.registerPlugin(InertiaPlugin);

            const MAP_DIMENSIONS = {
                width: 1920,
                height: 905
            };
            const SCALE = 2;

            let k = 1920/950;
            let $box = document.querySelector( '.playground' );
            let $map = element[0];
            let h = $box.clientHeight;
            let w = $box.clientHeight*k;

            window.addEventListener( 'resize', f );

            function getOffset(x, y) {
                const pointX = ($box.clientWidth / 2) + 100;
                const pointY = ($box.clientHeight / 2) + 32;
                const kh = $box.clientHeight / MAP_DIMENSIONS.height;
                const kw = $box.clientWidth / MAP_DIMENSIONS.width;
                const xk = x * kh;
                const yk = y * kw;

                // return {
                //     'x': -((xk - pointX) * SCALE),
                //     'y': -((yk - pointY) * SCALE)
                // }
                return {
                    'x': -(xk - pointX) * 1.7,
                    'y': -(yk - pointY) * 1.7
                }
            }
            function f(){
                Draggable.create( $map, {
                    type: 'x,y',
                    edgeResistance: 1,
                    bounds: $box,
                    inertia: true,
                    zIndexBoost:false,
                    snap: {
                        x: function(endValue) {
                            if( endValue > 0 ){
                                return 0;
                            }
                            if( endValue < -($map.clientWidth/1.1 - $box.clientWidth) ){
                                return -($map.clientWidth/1.1 - $box.clientWidth);
                            }
                        },
                        y: function(endValue) {
                            return -h*.05;
                        }
                    }
                });

            }

            scope.$watch( 'playground', ( newVal, oldVal ) => {
                if( newVal.x ){
                    const { x, y } = getOffset(newVal.x, newVal.y);

                    gsap.to( $map, {
                        x,
                        y,
                        scale: SCALE,
                        duration: .3,
                        ease: 'power1.out',
                        timeScale: .3
                    });
                } else {
                    const x = 0;
                    const y = 0;
                    gsap.to( $map, {
                        x,
                        y,
                        scale: 1,
                        duration: .3,
                        ease: 'power1.out',
                        timeScale: .3
                    });
                }
            } );

            f();
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );
})();
