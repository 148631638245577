import '../../../service/helper.js';
import '../../../service/dk/dk_limits.js';
import '../../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'denmarkProfileSelfExclusion' };

  controller.$inject = ['$scope', 'helper', 'dkLimits', 'popup'];

  function controller($scope, _helper, _dkLimits, _popup) {
    $scope.preloader = false;

    $scope.formData = {
      unit: '3',
      frozen_to: 0,
    };
    $scope.formField = {
      //- days
      3: {
        min: 31,
        max: 1000,
      },
      //-months
      5: {
        min: 1,
        max: 12,
      },
      //-years
      6: {
        min: 1,
        max: 1,
      },
    };

    $scope.submit = _helper.form.validator((form) => {
      _popup.open({
        name: 'selfexclusion',
        content: {
          unit: $scope.formData.unit,
          frozen_to: $scope.formData.frozen_to,
          action: 'BLOCK',
        },
      });
    });

    $scope.exclude = () => {
      _popup.open({
        name: 'selfexclusion',
        content: {
          action: 'EXCLUDE',
        },
      });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
