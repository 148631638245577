import user from '../../app/service/domain/user.js';
import popup from '../../app/service/domain/popup.js';
import paysystem from '../../app/service/domain/paysystem.js';
import user$ from '../../app/service/rx/user$.js';

(function () {

    'use strict';

    const directive = { name: 'betiniaPaymentIcon' };

    controller.$inject = [ 'user' , 'popup', 'paysystem', 'user$' ];
    function controller( _user , _popup , _paysystem, _user$){
        const loadPaySystems = () => {
            _paysystem.collection( { type: 'deposit' } )
                .then(r => {
                    _paymentSystems.push(...r.result || []);
                });
        };
        const getPaySystem = item => {
            return _paymentSystems.find(ps => ps.title.toLowerCase() === item.title.toLowerCase());;
        };
        const openDepositPopup = iconBoxItem => {
            const popupAlias = 'cashbox';
            const paySystem = getPaySystem(iconBoxItem);
            if (paySystem) {
                _popup.open({
                    name: popupAlias,
                    content: {
                        tab2: 2,
                        paySystem: paySystem
                    }
                });
            } else {
                _popup.open({
                    name: popupAlias,
                });
            }
        };
        const userSubscription = _user$.subscribe(user => {
            if (user.status) {
                loadPaySystems();
            }
        });
        const _paymentSystems = [];

        function link( scope, element, attrs ){
            const item = scope.$eval( attrs[directive.name] );
            const handleClick = () => {
                if (!_user.status) {
                    if(localStorage.getItem('rememberedLogin')) {
                        _popup.open({
                            name: 'login'
                        });
                    }else{
                        _popup.open({
                            name: 'registration'
                        });
                    }
                } else {
                    openDepositPopup(item);
                }
            }

            element[0].addEventListener('click', handleClick);

            scope.$onDestroy = () => {
                userSubscription.unsubscribe();
                element[0].removeEventListener('click', handleClick);
            };
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
