(function () {

    'use strict';

    const directive = { name: 'betiniaCheckedWb' };

    controller.$inject = [];

    function controller(){

        function link( scope, element, attrs ){

            scope.$watchCollection(
                "collection",
                function( ) {
                    if( scope.collection.length && scope.collection[0].trigger_codes ){
                        scope.formData.trigger_codes = scope.collection[0].trigger_codes;
                        scope.activeBonus = scope.collection[0].name;
                    }
                }
            );

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
