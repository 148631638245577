import user$ from '../../app/service/rx/user$.js';

(function () {
  'use strict';

  const directive = { name: 'addArrowsOnOverflow' };

  controller.$inject = ['$timeout', 'user$'];

  function controller($timeout, _user$) {
    function link(scope, element, attrs) {
      function setArrows() {
        $timeout(() => {
          scope.hasArrows = element[0].clientWidth > element[0].parentElement.clientWidth;
        }, 1000);
      }

      setArrows();

      const userSubscription = _user$.subscribe(setArrows);

      const onResize = () => setArrows();
      window.addEventListener('resize', onResize);


      scope.$on('$destroy', () => {
        userSubscription.unsubscribe();
        window.removeEventListener('resize', onResize);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
