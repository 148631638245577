import config from '../../app/service/configs/config.js';
import { gsap, TimelineMax, TweenMax } from 'gsap';
(function () {

    'use strict';

    const directive = { name: 'betiniaStockholmVideo' };

    controller.$inject = [ 'config' ];

    function controller( _config ){

        function link( scope, element, attrs ){

            const videoElement = scope.$eval( attrs[directive.name] );

            scope.preloaderVideo = true;

            const line = document.querySelectorAll('.is-animate-line');

            let showWrap = new TimelineMax({ paused:true})
                .to( '.playground-video-poster' , 0.2, { opacity: '1' } , '0' )
                .to( '.playground-video-wrap' , 0.2, { opacity: '1' } , '-=0.2' );

            line.forEach((line, i) => {
                showWrap.add( TweenMax.from( line, .25, { y: '15px' , opacity:'0' }) , '-=0.15' );
            });


            let prefix = 2560;
            if( window.innerWidth < 1440 ){
                prefix = 1440;
            }else if( window.innerWidth >= 1440 && window.innerWidth < 1920 ){
                prefix = 1920;
            }

            const video = document.querySelector(videoElement);

            if( scope.flow !== 'resume_play'){
                const source1 = document.createElement( 'source' );
                const source2 = document.createElement( 'source' );
                source1.src = `${_config.cdn}/betinia/se/playgrounds/video/desk/Stockholm_Start_desk_${prefix}.av1.mp4`;
                source1.type = 'video/mp4; codecs=av01.0.05M.08';
                source2.src = `${_config.cdn}/betinia/se/playgrounds/video/desk/Stockholm_Start_desk_${prefix}.mp4`;
                source2.type = 'video/mp4; codecs=avc1.4D401E';
                video.appendChild( source1 );
                video.appendChild( source2 );

                video.addEventListener('canplay', () => {
                    scope.preloaderVideo = false;
                } );
                video.addEventListener('ended', ()=> {
                    showWrap.play();
                } );
            }else{
                showWrap.play();
            }

            scope.skip = ()=> {
                scope.preloaderVideo = false;
                video.pause();
                showWrap.play();
            }
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
