import '../../../service/domain/system.js';
import '../../../service/domain/vip.js';
import '../../../service/configs/config.js';
import '../../../service/domain/account.js';
import '../../../service/domain/modal.js';
import '../../../service/helper.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
import '../../../service/datepicker/birthday-datepicker';

(function () {
  'use strict';

  const component = { name: 'lindaRegistrationForm' };

  controller.$inject = ['$scope', 'system', 'vip', 'config', 'account', 'modal', 'helper', 'contentBuffer', 'state', 'birthdayDatepicker', 'popup'];

  function controller($scope, _system, _vip, _config, _account, _modal, _helper, _contentBuffer, _state, _birthdayDatepicker, _popup) {
    $scope.countries = [];
    $scope.vip = [];

    $scope.preloader = false;
    $scope.isLoginSuccessful = false;
    $scope.page = 0;

    $scope.firstSubmit = true;

    $scope.country = {};

    $scope.showCpf = false;

    $scope.formData = {
      currency: '',
      country: '',
      gender: 'M',
      accept: false,
      gdpr_data: false,
      gdpr_marketing: false,
      enable_trigger_codes: true,
      phone: '',
    };

    $scope.currencyList = [];

    $scope.datepickerOptions = {
      minDate: _birthdayDatepicker.minDateValue,
      maxDate: _birthdayDatepicker.maxDateValue,
    };

    function prepareCurrencyList(list) {
      return list.map((i) => {
        return { key: i.code, name, icon: i.symbol };
      });
    }

    if (!_config.production) {
      $scope.formData.login = 'lo' + Date.now();
      $scope.formData.email = 'email' + Date.now() + '@yopmail.com';
      $scope.formData.password = 'qweQWE123';
      $scope.formData.retype = 'qweQWE123';
      $scope.formData.accept = true;
      $scope.formData.gdpr_data = true;
      $scope.formData.gdpr_marketing = true;

      $scope.formData.address = 'address' + Date.now();
      $scope.formData.birthday = '1999-01-01';
      $scope.formData.city = 'city';
      $scope.formData.name = 'name';
      $scope.formData.phone = '+1206456' + Math.round(Date.now() / 1000 / 1000 / 1000);
      $scope.formData.surname = 'surname';
      $scope.formData.postcode = 'p' + Date.now();
    }

    if (typeof $_GET.email !== 'undefined') {
      $scope.formData.email = $_GET.email;
    }

    if (_contentBuffer.read('popup') !== null) {
      Object.assign($scope.formData, _contentBuffer.read('popup').formData || {});
      $scope.page = _contentBuffer.read('popup').page || 0;
    }

    $scope.$watch('formData.trigger_codes', (triggerCode) => {
      $scope.triggerCode = triggerCode;
    });

    let cred = {};
    let formHasOwnRetype = false;

    const setRetype = () => {
      if (!$scope.formData.hasOwnProperty('retype')) {
        $scope.formData.retype = $scope.formData.password;
        return;
      }

      formHasOwnRetype = true;
    };

    const clearRetype = () => {
      if (!formHasOwnRetype) {
        delete $scope.formData.retype;
      }
    };

    $scope.preloader = true;
    _vip.collection().then(
      (answer) => {
        $scope.vip = answer.result;
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      },
    );

    $scope.preloader = true;
    _system.countries().then(
      (answer) => {
        watchCountry();

        $scope.countries = answer.result;
        if (!$scope.formData.country) {
          $scope.formData.country = answer.default;
        }
        $scope.preloader = false;
      },
      () => {
        $scope.preloader = false;
      },
    );

    function watchCountry() {
      $scope.$watch('formData.country', (newVal, oldVal) => {
        $scope.country = $scope.countries.filter((item) => item.code === newVal)[0] || {};
        $scope.currencyList = prepareCurrencyList($scope.country.currency) || [];
        $scope.formData.currency = $scope.currencyList[0].key;

        if ($scope.formData.phone?.length <= 6) {
          $scope.formData.phone = `+${parseInt($scope.country.prefix.replace(/\-/g, ''), 10)}`;
        }

        if (checkIfCpfIsNeeded()) {
          $scope.showCpf = true;
          $scope.formData.cpf = '';
        } else {
          $scope.showCpf = false;
          delete $scope.formData.cpf;
          delete cred.cpf;
        }
      });
    }

    function checkIfCpfIsNeeded() {
      return $scope.formData.country === 'BR';
    }

    function makePixel(uid, mid) {
      document.querySelector('#wl7starspartners_adsrv_eacdn_com')?.remove();

      const el = document.createElement('iframe');

      el.setAttribute('id', 'wl7starspartners_adsrv_eacdn_com');
      el.setAttribute('style', 'display:none');
      el.setAttribute(
        'src',
        'https://qwfh39851jtfvkurf21hf.com/Processing/Pixels/Registration.ashx?PlayerID=' + uid + '&mid=' + mid,
      );

      document.body.appendChild(el);
    }

    $scope.$watch('formData.trigger_codes', (triggerCode) => {
      $scope.triggerCode = triggerCode;
    });

    $scope.nextPage = _helper.form.validator((form) => {
      if ($scope.firstSubmit === true && $scope.formData.gdpr_marketing === false && $scope.page === 1) {
        $scope.firstSubmit = false;
        _modal.open('gdpr-registration').then(
          (answer) => {
            $scope.formData.gdpr_marketing = true;
            cred = Object.assign(cred, $scope.formData);
            $scope.page++;
          },
          (answer) => {
            cred = Object.assign(cred, $scope.formData);
            $scope.page++;
          },
        );
      } else {
        cred = Object.assign(cred, $scope.formData);
        $scope.page++;
      }
    });

    $scope.lastPage = _helper.form.validator((form) => {
      setRetype();

      cred = Object.assign(cred, $scope.formData);

      if (cred.enable_trigger_codes !== true) {
        delete cred.trigger_codes;
      }
      delete cred.enable_trigger_codes;

      $scope.preloader = true;
      _account.register(cred).then(
        (answer) => {
          if (answer.result.login) {
            // TODO: kostylyaka

            const uid = answer.result.prefix + answer.result.login;
            const mid = _config.mid;

            if (mid) {
              makePixel(uid, mid);
              setTimeout(function () {
                document.querySelector('#wl7starspartners_adsrv_eacdn_com')?.remove();
              }, 2000);
            }

            // TODO: kinec pershogo kosttlya

            $scope.isLoginSuccessful = true;

            if (window.RECONVERT) {
              window.RECONVERT.registerComplete();
            }
          }
          _state.readyToRedirectAfterAuth$.next();

          if (angular.isFunction(_popup.cb)) {
            _popup.cb();
            _popup.cb = null;
          }

          setTimeout(() => {
            form.$setSuccessful();
          }, 0);

          //                        _popup.close();
        },
        (answer) => {
          $scope.page = 1;
          $scope.preloader = false;
          clearRetype();
        },
      );
    });
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
