import '../../../service/domain/system.js';
import '../../../service/configs/config.js';
import '../../../service/dk/dk_account.js';
import '../../../service/domain/modal.js';
import '../../../service/helper.js';
import '../../../service/domain/content-buffer.js';
(function () {
  'use strict';

  const component = { name: 'denmarkRegistrationForm' };
  const analyticsPlace = 'denmark_form';

  controller.$inject = ['$scope', '$document', 'system', 'config', 'dk_account', 'modal', 'helper', 'contentBuffer'];

  function controller($scope, $document, _system, _config, _dk_account, _modal, _helper, _contentBuffer) {
    $scope.preloader = false;
    $scope.page = 0;
    $scope.firstSubmit = true;
    $scope.hasGamingActivity = false;

    $scope.formData = {
      accept: false,
      gdpr_data: false,
      gdpr_marketing: false,
    };

    if (!_config.production) {
      $scope.formData.login = 'lo' + Date.now();
      $scope.formData.email = 'email' + Date.now() + '@yopmail.com';
      $scope.formData.password = 'qweQWE123';
      $scope.formData.accept = true;
      $scope.formData.gdpr_data = true;
      $scope.formData.gdpr_marketing = true;

      $scope.formData.address = 'address' + Date.now();
      $scope.formData.city = 'city';
      $scope.formData.name = 'name';
      $scope.formData.phone = '+1206456' + Math.round(Date.now() / 1000 / 1000 / 1000);
      $scope.formData.surname = 'surname';
      $scope.formData.postcode = 'p' + Date.now();
    }

    if (typeof $_GET.email !== 'undefined') {
      $scope.formData.email = $_GET.email;
    }

    if (_contentBuffer.read('popup') !== null) {
      Object.assign($scope.formData, _contentBuffer.read('popup').formData || {});
    }

    let cred = {};

    function makePixel(uid, mid) {
      $document[0].getElementById('wl7starspartners_adsrv_eacdn_com')?.remove();

      const el = document.createElement('iframe');

      el.setAttribute('id', 'wl7starspartners_adsrv_eacdn_com');
      el.setAttribute('style', 'display:none');
      el.setAttribute(
        'src',
        'https://qwfh39851jtfvkurf21hf.com/Processing/Pixels/Registration.ashx?PlayerID=' + uid + '&mid=' + mid
      );

      document.body.appendChild(el);
    }

    $scope.nextPage = _helper.form.validator((form) => {
      if ($scope.firstSubmit === true && $scope.formData.gdpr_marketing === false && $scope.page === 1) {
        $scope.firstSubmit = false;

        _modal
          .open('gdpr-registration')
          .then((answer) => {
            $scope.formData.gdpr_marketing = true;
            cred = Object.assign(cred, $scope.formData);
            $scope.page++;
          })
          .catch((e) => {
            cred = Object.assign(cred, $scope.formData);
            $scope.page++;
          });
      } else {
        cred = Object.assign(cred, $scope.formData);
        $scope.page++;
      }
    });

    $scope.lastPage = _helper.form.validator((form) => {
      cred = Object.assign(cred, $scope.formData);

      if (!$scope.hasGamingActivity || !cred.gaming_activity) {
        delete cred.gaming_activity;
      }

      $scope.preloader = true;

      _dk_account
        .register(cred)
        .then((answer) => {
          if (answer.result.login) {
            //TODO: kostylyaka

            const uid = answer.result.prefix + answer.result.login;
            const mid = _config.mid;

            if (mid) {
              makePixel(uid, mid);
              setTimeout(function () {
                $document[0].getElementById('wl7starspartners_adsrv_eacdn_com')?.remove();
              }, 2000);
            }

            //TODO: kinec pershogo kosttlya

            if (window.RECONVERT) {
              window.RECONVERT.registerComplete();
            }
          }

          $scope.preloader = false;

          setTimeout(() => {
            form.$setSuccessful();
          }, 0);
        })
        .catch((e) => {
          $scope.page = 0;
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
