import achievement from "../../app/service/domain/achievement.js";
import config from "../../app/service/configs/config.js";
import system$ from "../../app/service/rx/system$.js";
import user from "../../app/service/domain/user.js";
import { gsap, Power2 } from 'gsap';
import * as THREE from 'three';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import ScrollTrigger from "../../libs/gsap/ScrollTrigger.min.js";
//
(function () {
    'use strict';

    const directive = { name: 'betiniaColossus' };

    controller.$inject = ['achievement', 'config', 'system$', 'user'];

    const lightBackParams = {
        color: 0xf2d1a2,
        intensity: 8,
        width: 12,
        height: 12,
        power: 1800.0
    };

    const lightProgressParams = {
        color: 0xffffff,
        intensity: 1.2,
        width: 12,
        height: 2
    };

    function controller(_achievement, _config, _system$, _user) {
        function link(scope, element) {
            const models = [];
            const container = element[0];
            const lockElement = document.querySelector('.colossus-lock');
            const progressTl = gsap.timeline({
                paused: true
            });
            const manager = new THREE.LoadingManager();
            const loader = new GLTFLoader(manager);

            let scene, camera, renderer;

            const rotationSpeed = 0.005;
            let direction = 1;
            let lightBack, lightProgress;
            let animationFrame;

            scope.collection = [];
            scope.current = 0;
            scope.progress = 2;
            scope.lock = true;
            scope.preloader = true;

            // initializing colossus
            function init() {
                loadColossus()
                    .then(() => {
                        let current = scope.collection.findIndex(i => i.status < 2);
                        scope.current = current !== -1 ? current : scope.collection.length - 1;
                        scope.total = getTotal(scope.collection.map((item) => item.prize), 0);

                        container.classList.remove('is-hidden');

                        initScene();
                        animate();

                        scope.lock = false;
                        scope.preloader = false;
                    });
            }

            // getting collection
            function loadColossus() {
                return _achievement
                    .list({
                        category: 'colossus'
                    })
                    .then(({ result }) => {
                        if (scope.collection && scope.current) {
                            Object.assign(scope.collection, result);

                            return;
                        }

                        scope.collection = result.sort((a, b) => a.progress[1] - b.progress[1]);
                    });
            }


            // loading model
            function loadModel(index, modelSize = 512) {
                if (models[index]) {
                    return models[index];
                }

                let model;

                loader.load(`/img/assets/models/${index}/model_${modelSize}.glb`, function (gltf) {
                    model = gltf.scene;
                    model.rotation.set(-.3, 0, 0);

                    switch(index) {
                        case 0:
                            model.scale.set(1.3, 1.3, 1.3);
                            model.position.set(-.075, .1, 0);
                            break;
                        case 1:
                            model.scale.set(1.33, 1.33, 1.33);
                            model.position.set(0, .95, 0);
                            break;
                        case 3:
                            model.scale.set(1.27, 1.27, 1.27);
                            model.position.set(0, .93, 0);
                            break;
                        default:
                            model.scale.set(1.3, 1.3, 1.3);
                            model.position.set(0, .1, 0);
                    }

                    scene.add(model);

                    models[index] = model;
                });

                return model;
            }

            // removing models
            function removeModelFromScene(models) {
                models.forEach(m => m.removeFromParent());
            }

            // initializing scene
            function initScene() {
                scene = new THREE.Scene();

                camera = new THREE.PerspectiveCamera(
                    45,
                    container.offsetWidth / container.offsetHeight,
                    0.1,
                    1000
                );

                renderer = new THREE.WebGLRenderer({
                    alpha: true,
                    antialias: true
                });

                renderer.setSize(container.offsetWidth, container.offsetHeight);
                renderer.setPixelRatio(window.devicePixelRatio);
                container.appendChild(renderer.domElement);

                lightBack = new THREE.RectAreaLight(
                    lightBackParams.color,
                    lightBackParams.intensity,
                    lightBackParams.width,
                    lightBackParams.height
                );

                lightProgress = new THREE.RectAreaLight(
                    lightProgressParams.color,
                    lightProgressParams.intensity,
                    lightProgressParams.width,
                    lightProgressParams.height
                );

                lightProgress.position.set(0, -1, 2);
                lightProgress.lookAt(0, -1, 0);

                scope.changeColossus(scope.current, true);

                lightBack.power = lightBackParams.power;
                lightBack.position.set(0, 0, -6);
                lightBack.lookAt(.5, .5, .5);

                camera.position.set(0, 4, 10);
                camera.rotation.set(-0.1, 0, 0);

                scene.add(lightProgress);
                scene.add(lightBack);
            }

            // animating colossus
            function animate() {
                if (!models) {
                    return;
                }

                if (models[scope.current]){
                    if (models[scope.current].rotation.y >= .7) {
                        direction = -1;
                    } else if (models[scope.current].rotation.y < -.4) {
                        direction = 1;
                    }

                    models[scope.current].rotation.y += rotationSpeed * direction;
                    renderer.render(scene, camera);
                }

                animationFrame = requestAnimationFrame(animate);
            }

            // animation for colossus lighting
            function updateLightProgress() {
                if (scope.pointsCurrent === 0) {
                    lightProgress.height = 0;
                    return;
                }

                if (scope.current === 0) {
                    scope.currentProgress = 2 + (11 * (scope.collection[scope.current].progress[2]/100) || 0);
                } else {
                    const progress = scope.pointsCurrent / scope.pointsNeeded;
                    scope.currentProgress = 2 + (11 * progress || 0);
                }

                // play no animation for colossus lighting when colossus is compeleted
                if (scope.collection[scope.current].status >= 2) {
                    lightProgress.height = scope.currentProgress;
                    progressTl.pause();
                } else {
                    progressTl.fromTo(lightProgress,
                        { height: 0 },
                        {
                            height: scope.currentProgress,
                            duration: 1,
                            ease: Power2.easeInOut
                        }
                    );
                    progressTl.play();
                }
            }

            // getting progress
            function getProgress(current) {
                let progress;
                if (current === 0) {
                    scope.pointsCurrent = scope.collection[scope.current].progress[0];
                    scope.pointsNeeded = scope.collection[scope.current].progress[1];

                    progress = optimizeProgress(
                        scope.collection[scope.current].progress[0],
                        scope.collection[scope.current].progress[1]
                    );
                } else {
                    const pointsCurrent = scope.collection[scope.current].progress[0] - scope.collection[scope.current - 1].progress[1];
                    const pointsNeeded = scope.collection[scope.current].progress[1] - scope.collection[scope.current - 1].progress[1];

                    scope.pointsCurrent = pointsCurrent < 0 ? 0 : pointsCurrent;
                    scope.pointsNeeded = pointsNeeded;

                    progress = optimizeProgress(
                        pointsCurrent,
                        pointsNeeded
                    );
                }

                return progress;
            }

            // optimize progress
            function optimizeProgress(current, total) {
                const result = Math.round((current / total) * 100);

                return result > 2 ? result : 2;
            }

            // getting total prizes value
            function getTotal(collection, initialValue) {
                return collection.reduce((accumulator, currentValue) => accumulator + currentValue,
                    initialValue
                );
            }

            // switching between colossuses
            scope.changeColossus = (index, force = false) => {

                if ((index === scope.current || scope.lock) && !force) {
                    return;
                }

                scope.current = index;
                scope.progress = getProgress(scope.current);
                scope.completed = scope.collection[scope.current].progress[0] >= scope.collection[scope.current].progress[1];
                scope.inProgress = (index === 0 && scope.collection[index].status < 2) || (index > 0 && scope.collection[index].status < 2 && scope.collection[index-1].status >= 2);

                 // used style attribute as ng-show/ng-if has some timeout on lock appearing on colossus change
                 lockElement.style.display = 'none';

                 if (!_user.status || (index !== 0 && scope.collection[index].status < 2 && scope.collection[index-1].status < 2)) {
                     lockElement.style.display = 'block';
                 }

                removeModelFromScene(models);
                const model = loadModel(index);

                scene.add(model);

                updateLightProgress();
            }

            manager.onStart = function () {
                scope.preloader = true;
            };

            manager.onLoad = function () {
                scope.preloader = false;
            };

            manager.onError = function (url) {
                console.log('There was an error loading ' + url);
            };

            function handleResize() {
                camera.aspect = container.offsetWidth / container.offsetHeight;
                camera.updateProjectionMatrix();

                renderer.setSize(container.offsetWidth, container.offsetHeight);
                renderer.setPixelRatio(window.devicePixelRatio);
                renderer.render(scene, camera);
            };

            window.addEventListener('resize', () => handleResize());

            init();

            scope.$on('$destroy', () => {
                window.removeEventListener('resize', () => handleResize());
                window.cancelAnimationFrame(animationFrame);
                models.length = 0;
            });
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive(directive.name, controller);
})();
