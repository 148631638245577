import '../service/trustly/paynplay.js';
import '../service/rx/post-message$.js';
import '../service/domain/state.js';
import '../service/domain/popup.js'
import '../service/configs/config.js'
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const component = { name: 'authenticationProvider' };

(function() {
  'use strict';

  controller.$inject = [
    '$scope',
    'paynplay',
    '$window',
    'postMessage$',
    'state',
    'popup',
    'config',
  ];

  function controller(
    $scope,
    _paynplay,
    $window,
    _postMessage$,
    _state,
    _popup,
    _config,
  ) {
    let providerWindow;
    let payment;
    let width;
    let height;
    let noHomepageRedirect = false;
    let noPopup = false;
    let callbackSubscription;

    const destroy$ = new Subject();

    this.$onChanges = () => {
      payment = this.paymentMethod;
      noHomepageRedirect = this.noHomepageRedirect !== undefined;
      noPopup = this.noPopup !== undefined;
      width = this.windowWidth || 800;
      height = this.windowHeight || 500;

      $scope.openProvider = noPopup ? goToProviderPage : openProfiderWindow;
    };

    const isUserProfileFulfilled = (user) => {
      return user.profile.is_profile_complete === true && !user.requiredFields.length;
    };

    function openProfiderWindow() {
      const left = ($window.innerWidth - width) / 2;
      const top = ($window.innerHeight - height) / 2;

      // providerWindow = true ? $window : $window.open(
      providerWindow = _config.browser.webView ? $window : $window.open(
        '/preloader.html',
        '_blank',
        `height=${height},width=${width},left=${left},top=${top}`,
      );

      _paynplay
        .init({
          paymethod: payment,
          amount: 0
        })
        .then(({ result }) => {
          // localStorage.setItem('outside', true);
          localStorage.setItem('outside', _config.browser.webView);
          providerWindow.location = result.url;
        });

      listenForMitIDCallback();
    }

    function goToProviderPage() {
      _paynplay
        .init({
          paymethod: payment,
          amount: 0,
          locale: 'en',
        })
        .then(({ result: { url } }) => {
          localStorage.setItem('outside', true);
          window.location = url;
        });
    }

    function listenForMitIDCallback () {
      callbackSubscription = _postMessage$
        .pipe(
           takeUntil(destroy$),
           filter(({ message }) => message === 'callback'),
           filter(({ params }) => params.action === 'devcodeSuccess')
        )
        .subscribe(async ({ params }) => {
          const config = {
            token: params.code,
            paymethod: payment,
            order_id: params.state,
          };
          _paynplay
            .auth(config)
            .then(({ result }) => {
              providerWindow.close();
              callbackSubscription?.unsubscribe();
              if (!isUserProfileFulfilled(result)) return;
              if (noHomepageRedirect) {
                _popup.close();
              } else {
                _state.goto('home');
              }
            })
            .catch(() => {
              providerWindow.close();
            });
        });
    }

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    bindings: {
      paymentMethod: '<',
      windowWidth: '<',
      windowHeight: '<',
      noHomepageRedirect: '<',
      noPopup: '<',
    },
    template: app.getTU(component.name),
  });
})();
